@import "../../assets/sass/colors";
 .serachdash{
 position:relative;
   input{
           border: none;
    border-radius: 26px;
    font-size: 11px;
    padding: 10px 40px;
   }
   i{
    position: absolute;
    color: #88888b;
    top: 11px;
    font-size: 16px;
    left: 13px;
   }
 }
    .dashboard-header_conatiner{
      display: flex;
          margin-top: 5px;
    justify-content: flex-end;
    align-items: center;
      a{
        margin: 0 15px;
        position:relative;
        .spannotf{height: 17px;}
       span{
         position: absolute;
    background: #f12a2a;
    padding: 0px 4px;
    height: 18px;
    width: 17px;
    text-align: center;
    border-radius: 50%;
    color: #FFF;
    font-size: 10px;
    padding-top: 2px;
    top: -8px;
    right: -7px;
       }
       img{
          width: 21px;
       }
      }
    }
 
 

@media only screen and (max-width: 1024px) {
     
}
