@import "../../../assets/sass/colors";
.besoin8 {
.btnwthbg{
       background: #FFF !important;
    color: #073460;
    border: 1px solid #073460;
}
.imglg{ 

    width: 300px;
    margin-top: 169px;
    text-align: center;
    margin: 0 auto;
    margin-top: 170px;
    padding-bottom: 43px;
    border-bottom: 5px solid #073460;
    position:relative;
   
   
 }
 .lgposabs{
   background-repeat: no-repeat;
    background:url('../../../../public/images/chrono.png');
     width: 70px;
      height: 76px;
      position:absolute;
          top: -14px;
    left: -47px;
    position: absolute;

 }
.bodyrecap {
    
    padding: 30px;
    
}
  .section-title{
    h2{
      color:#e99f28;
          font-size: 23px;
      span{
        color:#073460;
        display:block;
            font-size: 18px;
      }
    }
    h5{
     font-family: 'Montserrat-Medium';
    }
  }
  .bodyrecap{
    ul.ticketul{
    padding:0px;
    margin:0px;
      li{
         list-style:none;
             padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
         span{
               padding-top: 1px;
               padding-left: 10px;
         }

         img{
            width: 17px;
         }
      }
    }

  ul.ticketconf{
    padding:0px;
    margin:0px;
      li{
         list-style:none;
             padding-top: 5px;
             padding-bottom: 5px;
     
         span{
               padding-top: 1px;
               padding-left: 10px;
         }

         img{
            width: 17px;
         }
      }
    }

  }
}

@media only screen and (max-width: 1024px) {
    
}
