@import "../../../assets/sass/colors";
#besoin6 {
   .main-register-holder {
    
    margin: 10px auto 50px;
  }  

   
}

@media only screen and (max-width: 1024px) {
    
}
