@import "../../../assets/sass/colors";

 .showrapportrestreinte{

    h2{
        color: rgb(7, 52, 96);
    font-size: 24px;
    }
     h3{
           color: rgb(7, 52, 96);
    font-size: 15px;
    }
     h4{

         color: rgb(7, 52, 96);
         font-size: 18px;
    
    }
    label{
         color: rgb(7, 52, 96);
    font-size: 12px;
        display: block;
    }
    .co009{
      background:#dcf2fb;
      border-radius:10px;
      padding:20px;
      .row{
       margin-bottom:10px;
      }
    }
    .t9987{
         background: #FFF;
    border-radius: 8px;
    padding: 4px 24px;
    width: max-content;
        font-weight: bold;
    }
    .flxcol9{
         display: flex;
    align-items: center;
    justify-content: space-between;
    }

    .flx9987{
          display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    label{
     width:50%;
    }
    }
    .pa009{
          color: #073460;
    font-weight: bold;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 7px;
    margin-top: 7px;
    font-size: 13px;
    }
 }
.restreintes{
   .btngreen{
       background: #4ec5ca;
    padding: 9px 17px;
    border-radius: 11px;
    color: #FFF;
    display: block;
    width: max-content;
    margin: 0 auto;
    cursor: pointer;
   }
   .flx998{
    display: flex;
    align-items: center;
    justify-content: space-between;
   }
   .section298 {
    padding-left: 6%;
    margin-bottom: 20px;
}
input{
   border: 1px solid #D6D6D6;
}
.btnrounded{
      color: #2e3f6e;
    font-weight: bold;
    border: 1px solid #4EC5CA;
    padding: 5px 12px;
    border-radius: 25px;
}
}
@media only screen and (max-width: 1024px) {
    
}
