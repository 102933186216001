@import "../../assets/sass/colors";
.listing {
   padding-top: 79px;
   .disnone{display:none}
   .container{padding-top: 42px;}
   .tabs-menu {padding:0px}
   .mrg556{margin-bottom: 15px !important;}
    .listing-item{
     width: 100%;
   }
   .grid-item-holder{
     height:auto !important
   }
   .geodir-category-img-wrap img{
       height: 161px;
   }
   .filter-sidebar-header .tabs-menu li a {
   
    font-weight: bold;
    font-size: 14px;
    
}
   .toggle-mission-btn{background: #5ECFB1;    color: #fff;border: 1px solid #e5e7f2;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    height: 48px;
    line-height: 48px;
    float: left;
    width: 100%;
   
    transition: all .2s ease-in-out;
    position: relative;
    overflow: hidden;}
   .filter-sidebar-header{margin-bottom:0px}
   .filter-sidebar-header .tabs-menu li{width:100%}
   .geodir-category-text{display:none}
   .date-container2 {
   
    margin-bottom: 0px !important;
}
   .blockbg55{
    position: relative;
    float: left;
    width: 100%;
    float: left;
    border: 1px solid #e5e7f2;
    background: #f9f9f9;
    margin-bottom: 20px;
    padding: 15px 20px 15px 45px;
    border-radius: 4px;
    color: #7d93b2;
    font-size: 12px;
    -webkit-appearance: none;
    outline: none;
    overflow: hidden;
    z-index: 1;
      i {
          padding-right: 12px;
          font-size: 14px;
          position: absolute;
          top: 16px;
          left: 16px;
          color: #4DB7FE;
      }

      .mb-3{
        margin-bottom: 0px !important;
      }
      .x778{margin-bottom: 20px !important;}
   }
   .custom-form textarea {
    height: 125px;
   }
}




@media only screen and (max-width: 1024px) {
    .listing {
    }
}
