@import "../../assets/sass/colors";
.restau-item {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.01);
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    transition:500ms ;
    border:3px solid white;
    &:hover{
        transform: scale(1.01);
        border-color:$primary;
    }
    .image{
        width: 100%;
        height: 250px;
        background-color: $grey2;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .info{
        padding: 15px;
        h5{

        }
        .address{
            margin-bottom: 10px;
        }
        .category{
            font-family: 'Montserrat-Bold',sans-serif;

        }
        .delivery{
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .restau-item {
    }
}
