@import "../../../assets/sass/colors";
.detailmission {
 
    a{cursor:pointer}
    .brd89{
      border-bottom: 1px solid #e3e3e3;
    }
   .prfimg{
       width:30px;height:30px;border-radius: 20px;
    }
    .user-profile-header_media_title h3{
      color:#FFF
    }
    .user-profile-header_media{    padding: 62px 0 20px 0;}
 
    .para{
      color:#666666
    }
    .btn9987{
       position: relative;
    height: auto;
    overflow: hidden;
    width: auto;
    float: right;
    right: unset;
    top: unset;
    left: unset;
    }
    .btn776{
    margin-right:20px
    }


}

@media only screen and (max-width: 1024px) {
    
}
