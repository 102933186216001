@import "../../../assets/sass/colors";
#besoin5 {
  
  .slick-next,.slick-prev {
    
    top: 21px;
        width: 20px;
    height: 20px;
    content: ''  !important;
    background-repeat: no-repeat !important;
    background-size: 50px  !important;
    background-position: center  !important;
}
  .slick-prev {
    
   background:url('../../../../public/images/boutonretour.png')
}
  .slick-next {
    
   background:url('../../../../public/images/boutonavance.png')
}
.slick-prev:before , .slick-next:before {
    display:none
}

  .cardservicetel{
box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 10px;
    background: #FFF;
    padding: 10px;
   img{
    width: 25px;
    margin-right: 7px;
   }
  
   span.h4{
   color: #073460;
    font-size: 14px;
   
   }
    p{
          font-size: 12px;
    color: #073460;
    margin-top: 13px;
      strong{

      }
   }
 
}
   .htserv1{
    color:#073460;
    font-weight:bold;
    margin-bottom:10px;
    font-size: 13px;
    .ht1{
    img{
      width: 25px;
       margin-right: 10px;
    }
      
    }
     .ht4{
           font-weight: normal;
    color: #073460;
    font-size: 10px;
    }
     .ht3{
      color:#e99f28
    }


   }
   .bgplaning{
     box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 10px;
    background: #FFF;
   }

   .calendar{
   padding: 20px;
     .colcalendar{
padding-left: 10px;
padding-right: 10px;
        h5{
              color: #073460;
    font-size: 15px;
    text-align: center;
    margin-bottom:2px;
        }
        .dd{
           color: #073460;
    font-size: 12px;
    display: block;
    text-align: center;
        }
        .actions{
        margin-top: 15px;
          div{
                   padding: 10px;
    border: 1px solid #073460;
    border-radius: 30px;
    text-align: center;
    color: #073460;
    font-weight: bold;
    margin-bottom: 15px;
    cursor:pointer;
          }
          div.active{
        border:1px solid #4ec5ca;
          }
        }
     }
   }
 .logoplang{
 margin-top:15px;
 margin-bottom:30px;
 h5{
  font-size:15px;
  color:#073460;
  padding-left:20px;
  margin-bottom:30px;
 }
   ul{
     margin:0px;
     padding:0px;
      li{
        img{
          margin:0 auto;
        }
      }
   }
 }
}

@media only screen and (max-width: 1024px) {
    #besoin5 {
        
    }
}
