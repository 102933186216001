@import "../../assets/sass/colors";

.main-dashboard-sec {

.MuiSwitch-colorSecondary.Mui-checked {
    color: #07345F;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
    background-color: #07345F;  
}
.profile-edit-container {
    padding: 0px 10px 10px 10px;
    border: 0px;
}
.btcustom{
     margin-top: 0;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-radius: 2px;
    padding-left: 30px !important;
    padding-right: 30px !important;
}
  form {
  margin-bottom:20px;
        background-color: white;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 3px 13px 27px -19px rgba(0, 0, 0, 0.11);
        overflow: hidden;
         select{
      -webkit-appearance: auto;
      border: 1px solid #e5e7f2;
    padding-top: 6px;
    padding-bottom: 6px;
    background: #FFF;
    border-radius: 4px;

 }
 input{
      padding-left: 18px !important;
      margin-bottom:0px !important;
      padding-top: 6px !important;
    padding-bottom: 6px !important;
 }
 input,label{
  color: #627e9a ;
 }
 .mrgbtm{
 margin-bottom:12px;
 }
    }
.rowtitel{
     display: flex;
     align-items: center;
         margin-bottom: 20px;
    h3{
     font-size: 15px;
    letter-spacing: -0.5px;
    color: #07345F;
    margin: 0;
    margin-left: 16px;
    border: 0px;
    }
    img{
      width: 45px;
    }
}
.nopaddingleft{
  padding-left:0px
}
.nopaddingright{
  padding-right:0px
}
.w100{
width:100%;
     img{
          width: 101%;
     }
}
.sdp {
    margin:0 auto;
        background: #f2f2f2;
}
.sdp--square-btn{
box-shadow: none;
background: #f2f2f2;
    svg{
      background: #073460;
    color: #FFF;
    border-radius: 12px;
    padding: 5px;
    }
}
.process-item{
min-height: 110px;
  box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 10px;
    background: #FFF;
    margin-bottom:20px;
        padding: 10px;
         text-align: center;
     img{
      width: 60px;
    margin-top: 8px;
    height: 50px;
     }
     .lrgimg{
          width: 92px;
    margin-top: 23px;
    margin-bottom: 10px;
    height:auto
     }
     h3{
             font-weight: normal;
    font-family: 'Montserrat-regular',sans-serif;
    font-size: 13px;
    color: #073460;
    text-align: center;
    padding-top: 10px;
     }
}

 .dashbordassitantcontent{
  background: #f6f6f8;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 823px;
}

}
    .clrbl10{
        color: #073460;
         font-size: 12px;
        
  }
      .clrbl11{
        color: #2e3f6e !important;
         font-size: 13px !important;
         font-weight:bold;
        
  }

        .clrbl21{
        color: #e99f28 !important;
         font-size: 15px !important;
         font-weight:bold;
        
  }
    .clrbl90{
        color: #073460;
         font-size: 18px !important
        
  }
  .mdflx99{
   margin-top:25px;
       margin-bottom: 25px;
       display: flex;
    align-items: center;
   label{
    color: #073460;
         font-size: 12px;
   }
   input{
margin-right:10px
   }
  }
  .btnmodal{
       border-radius: 7px !important;
    padding: 6px 11px !important;
  }
  .btnmodalbrd{
   border: 1px solid #073460 !important;
    color: #073460 !important;
  }
@media only screen and (max-width: 1024px) {
    .main-dashboard-sec {
    }
}
