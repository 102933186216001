@import "../../../assets/sass/colors";

.cad778{
  background:#EEEEEE;
  padding:10px;
  .ff9{
       display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
   label{
    color:#073460
   }
   strong{
    color:#073460
   }
  }
}


.detailmission {
      .inputFileInvis {
          background: transparent;
    opacity: 0;
    position: absolute;
    z-index: 99999;
    width: 100%;
    left: 0px;
    margin: 0;
    top: 0;
}

.flx881 {
  margin-bottom: 0px;
    width: 100%;
    float: right;
    display: flex;
}
.btn9886{
      background: #E99F28 !important;
    float: right !important;
     padding: 10px 20px !important;
}
.btn98996 {
    background: #2e3f6e !important;
    float: right !important;
    padding: 10px 20px !important;
}
.flx00983{
       display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position:relative;
    h4{
     color:#566985 !important
    }
}
.p339383{
       position: absolute;
    right: 7px;
    top: -39px;
}
.labbnrd{    border-radius: 10px !important;
    color: #FFF;
    font-weight: bold;
    text-align: center;}
    a{cursor:pointer}
    .brd89{
      border-bottom: 1px solid #e3e3e3;
    }
   .prfimg{
       width:30px;height:30px;border-radius: 20px;
    }
    .user-profile-header_media_title h3{
      color:#FFF
    }
    .user-profile-header_media{    padding: 62px 0 20px 0;}
 
    .para{
      color:#666666
    }
    .btn9987{
       position: relative;
    height: auto;
    overflow: hidden;
    width: auto;
    float: right;
    right: unset;
    top: unset;
    left: unset;
    }
    .btn776{
    margin-right:20px
    }


}

@media only screen and (max-width: 1024px) {
    
}
