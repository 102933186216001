@import "../../../assets/sass/colors";
    .msgprofile{
       width: 80px;
       height: 80px;
       border-radius: 50%;
       margin-bottom:10px
    }
.dashboard {

    .progress {
    overflow: hidden;
    height: 7px;
    margin-bottom: 9px;
    background-color: #fafafa;
    border-radius: 4px;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.11); 
    }

    a{cursor:pointer}
    .brd89{
      border-bottom: 1px solid #e3e3e3;
    }
   .prfimg{
       width:30px;height:30px;border-radius: 20px;
    }
    .progress-bar {
    background: #FFB600;
    }
    .sr-only {
    position: absolute;
    text-align: right;
    line-height: normal;
    display: block;
    height: auto;
    overflow: unset;
    color: #FFF;
    clip: unset;
    padding-left: 82px;
    font-weight: 700;
    }
    .para{
      color:#666666
    }

 .datepickercnt{
 background: #FFF;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-radius: 8px;
  .f12{
  display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    h5{
     color: #073460;
    font-size: 17px;
    }
    h4{
     font-size: 15px;
    color: #073460;
    letter-spacing: -0.5px;
        margin: 0;
    }
    a{
     img{
      width: 26px;
     }
    }
  }
  .f13{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1px;
    padding-left: 25px;
    padding-bottom: 15px;
    div{
      display: flex;
      align-items: center;
      font-size: 12px;
    margin-right: 10px;
      span{
      margin-right: 5px;
       width: 15px;
    height: 15px;
    display: inline-flex;
    border-radius: 12px;
      }
      .cl1{
        background:#4ec5ca
      }
      .cl2{
        background:#e99f28
      }
      .cl3{
         background:#1747ee
      }
    }
  }
 }
}

@media only screen and (max-width: 1024px) {
    
}


.react-datepicker__month-container {
  float: left;
  background: #f2f2f2;
  margin: 0 auto;
  border-radius: 0.5rem;
}


.react-datepicker{

  border: none;
  padding: 0.75rem;
  background: #f2f2f2;
}

.react-datepicker__header{
border-bottom: 0px;
}
.react-datepicker__current-month{
font-family: Montserrat-Bold,sans-serif;
  color: #6c6c6c;
}

.react-datepicker__navigation-icon{
top:5px
}

.react-datepicker__navigation{top:7px}

.react-datepicker__navigation{
  background: #073460;
  border-radius: 19px;
  color: #fff;
  padding: 5px;
  width: 24px;
  top: 18px;
  height: 24px;
}
.react-datepicker__navigation--next {
  right: 11px;
}
.react-datepicker__navigation--previous {
  left: 10px;
}
.react-datepicker__navigation-icon--next {
  left: 1px;
}
.react-datepicker__navigation-icon--previous {
  right: 0px;
}

