@import "../../../assets/sass/colors";

.editservices{

    .rowflex{
    margin-bottom:15px;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    .labelswt{
      min-width: 120px;
    }
     .item{
         margin-right: 20px;
        label{
        position:relative;
             display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0px;
            strong{
              padding-right: 10px;
            }
            span.euro{
                  position: absolute;
                    right: 6px;
                    z-index: 9999;
                    font-weight: bold;
            }
        }
     }
    }
    .itemtag{
             margin-right: 10px !important;

      label{
        width: max-content;
         border: 1px solid #07345F;
         border-radius: 20px;
         padding: 9px 18px;
         span{
               padding-left: 9px;

         }
      }
    }

    .mainouvre{
        text-align: center;
      img{

      }
      p{
          box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 10px;
    background: #FFF;
    text-align: center;
    color: #073460;
    font-weight: bold;
    font-size: 12px;
    padding: 10px;
      }
    }
    .linkorgn{
     color:#e99f28;
     display:block
    }
    .lileft{float:left !important}
}

@media only screen and (max-width: 1024px) {
    
}
