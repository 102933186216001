@import "../../../assets/sass/colors";

.devis{
.ff56{
   margin-bottom: 0px !important;
}
  .rowtiteldevis{
    background: #fbecd4;
    border-radius: 9px;
    padding: 12px 20px;
    font-weight: bold;
    color: #073460;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

  }
 .noraduis{
       border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    .flmi90990{
     a{

     }
     img{
      width: 25px;
     }
    }

      .custom-form{
      box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 10px;
    background: #FFF;
        padding: 10px;
  }
  .actsr55{
       font-size: 12px;
       width: 136px;
  }

  .actsearch{
    input{
    border-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 30px;
    }
    
    button{
          margin-top: 0;
    padding-top: 4px !important;
    padding-bottom: 3px !important;
    border-radius: 2px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 158px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    }
  }

  .absposcal{
       position: absolute;
       right: 15px;
  }
  .flx889{
   position:relative;
  }
  .inp778{
      border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; 
    margin-right:8px;
  }
  .mr778{
   margin-top: 10px;
    margin-bottom: 35px;
  }
  table{
   thead{
     th{
          font-size: 13px;
          color: #2e3f6e;
          font-weight:bold;
     }
   }

tbody{
     td{
          font-size: 12px;
          color: #2e3f6e;
          
     }
   }

  }
  .dstb{
    select,input{
      border: 1px solid #2e3f6e;
      height: 30px;
    border-radius: 4px;
    }
  }

    .rowflex1{
     
  
     .item{
        
        label{
        position:relative;
             display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0px;
            strong{
              padding-right: 10px;
            }
            span.euro{
                  position: absolute;
                    right: 6px;
                    z-index: 9999;
                    font-weight: bold;
            }
        }
     }
    }
    .bigTitle{
         border-top: 1px solid #2e3f6e;
    margin-top: 74px;
    border-bottom: 1px solid #2e3f6e;
    padding-top: 10px;
    padding-bottom: 12px;
    label{
           padding-top: 6px;
    }
    }
    .listclient{
    .flx889{
         padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ebeef0;
    }
     .item{
     span{
      padding-left:10px
     }
       img.bnb{
        width: 90px;
       }
     }
    }
  }

 


@media only screen and (max-width: 1024px) {
    
}
