@import "../../../../assets/sass/colors";
.aideDomicile1{
  .boxShadow{
    box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
    -webkit-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
    -moz-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);
  }

  .header1{
    width: 90%;
    margin: 0px auto;
    height: 40px;
    background: rgb(7, 52, 96);
    color: #fff;
    text-align: center;
    padding: 10px;
    font-weight: bold;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  } 
  .body1{
    width: 90%;
    margin: 0px auto;
    min-height: 100px;
    color: rgb(7, 52, 96);
    background: #fff;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  #adresse{padding: 10px !important;}
  .btn{
    text-align: center;
    padding: 10px !important;
  }

  .alert{padding: 0.5rem 0.5rem !important;}
}