@import "../../assets/sass/colors";

 

.biens {
.process-wrap li{
   padding:0 14px;
}
.process-img{
   min-height: 165px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
        margin-bottom: 17px;
}
.offresection{
  padding:0px
}
.slick-prev:before{
  
  color:#999999;
   font-size:41px;
    width: 30px;
    background-size: cover;
    height: 30px;
    display: block;
    background-repeat: no-repeat;
}
.slick-next:before{
     
   color:#999999;
   font-size:41px;
    width: 30px;
    background-size: cover;
    height: 30px;
    display: block;
    background-repeat: no-repeat;
}


.bg-parallax-wrap{
      background-image: url('../../../public/images/Home_2.jpg');
      background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.offresection{
  .process-item{
      padding: 10px;
  box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 10px;
    background: #FFF;
    margin-bottom:20px;
  }
}
.bg-parallax-wrap, .bg-tabs-wrap{
 
}
.mxp99{
      max-width: 60%;
}
   .slick-next {
   right: 9%;
    top: 90%;
    z-index: 9999;
    position: absolute;
}
.slick-prev{
     left: 7%;
    top: 90%;
    z-index: 9999;
    position: absolute;
}
.slick-slider {
    margin-top: 28px;
}
.main-search-input{padding-right:0px}
.serachinput{
  border-radius: 30px;
    padding-right: 20px
}
.intro-item h1{text-align:center; font-size:35px}

.main-search-button_2 {
    position: absolute;
    right: 12px;
    width: 40px;
    color: #fff;
    height: 40px;
    top: 13px;
    bottom: 10px;
    text-align: center;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background: #14a6e4;
}
.listitem{
        cursor: pointer;
        border-bottom:1px solid #eee;
        background-color:#FFF;
        text-align:left;
        padding:10px;
        z-index:9999;
    }
.tab667{width: 67%;}
.autocomplete-dropdown-container{
      z-index: 99999;
    position: absolute;
    width: 100%;
    top: 40px;
}
.sectionservices{
   .col-md-3{
   margin-bottom:20px
   }
}

.pl-0{
  padding-left:0px !important
}
.san88{
    color: #ccc;
        top: 8px;
    left: 4px;
    position:absolute;
}
.san99{
    color: #ccc;
        top: 8px;
    right: 8px;
    position:absolute;
}
.markerinput{
   padding-left: 32px !important;
}
.cadre778{
    color: #073460;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0px;
    background:#e8eef7;
    padding:20px;
    text-align: center;
    border-radius:10px;
   h5{
    color:#e99f28
   }
   p{
   color: #073460;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0px;

   }

}
.xx9{
  font-size: 16px;
    color: #e99f28;
}
.xx19{
  font-size: 16px;
    color: #9ddbdb;
}
.xx119{
  font-size: 16px;
    color: #1747ee;
}
.xx1119{
  font-size: 16px;
    color: #14a6e4;
}
.xx11119{
  font-size: 16px;
    color: #65cbcf;
}
.bg0098{
      height: 243px;
    background-repeat: no-repeat;
    background-position: center 1px;
    background-size: 300px;
}
.sectconfi90{
 background: #FFF;
    padding: 60px 0px !important;
    ul{
      overflow: hidden;
      width: 368px;
    margin: 0 auto;
    padding: 0;
    }
    
   li{
      list-style: none;
    float: left;
    margin: 0px 23px;
   }
}
.sectionbesoin{
   img{
      min-height: 172px;
      width: 100%;
      margin-bottom:20px;
   }
   h6{
     color: #073460;
    font-weight: bold;
    font-size: 18px;
    padding-left: 12px;
   }
   p{
    color:#4a6784;
    padding-left: 12px;
   }
}
.cadre888{
    color: #073460;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0px;
    background:#f8f4ee;
    padding:20px;
    text-align: center;
    border-radius:10px;
    padding-bottom: 0px;
   h5{
    color:#e99f28
   }
   p{
   color: #073460;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0px;

   }

}

.sect98{
  line-height: 26px;
    margin-top: 16px;
}
.form-group--nest {
    display: flex;
    flex-flow: row nowrap;
}
.form-group--nest input {
    border-radius: 4px 0 0 4px;
    border-color: #ccc;
    border-right: none;
    font-size: 14px;
   box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
}
.form-group--nest .ps-btn {
    border-radius: 0 4px 4px 0;
    padding-left: 25px;
    padding-right: 25px;
        display: inline-block;
    padding: 15px 45px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #FFF;
    border: none;
    font-weight: 600;
    border-top-right-radius: 4px;
     border-bottom-right-radius: 4px;
    background-color: #073460;
    transition: all .4s ease;
    cursor: pointer;
}
.cadre779{
    color: #073460;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0px;
    background:#e8f4f4;
    padding:40px;
    text-align: center;
    border-radius:10px;
   h5{
    color:#073460
   }
   p{
   color: #94a9b8;
    font-size: 15px;
    font-weight: normal;
    overflow: hidden;
    margin-bottom: 0px;

   }
   a{
   background: #4ec5ca;
    padding: 20px 50px;
    border-radius: 39px;
    color: #FFF;
    display: block;
    width: max-content;
    margin: 0 auto;
    margin-top: 23px;
    cursor:pointer
   }

}
.sectionvideos{
  
}
.p889{    max-width: 58%;}
.sectOK998{
img{
  float:left;
  width: 37px;
}
  ul{
   margin-top:0px;
   float:left;
    li{
           color: #073460;
    font-size: 14px;
    list-style:none;
    }
  }
}
.cms778{
margin-bottom:10px;
  img{
  width:100%
  }
}
.cardservice{
box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 18px;
    background: #FFF;
   img{
    width: 100%;
    border-radius: 10px;
   }
  
   h4{
   color: #073460;
    font-size: 14px;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
   }
}
.radionbtnhome{
   width: 300px;
    margin: 0 auto;
        margin-top: 40px;
    ul{
        margin: 0px;
    padding: 0px;
    background: #073460;
    border-radius: 30px;
    padding: 5px;
        overflow: hidden;
      li{
          list-style: none;
    float: left;
    width: 50%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #FFF;
   
      }
          li.active{
            background: #FFF;
    border-radius: 30px;
    color: #073460;
         
      }

    }
}
.intro-item h3{
 text-align:left;font-weight: 100;
    font-size: 17px;
    font-family: 'Montserrat-Medium';margin-bottom: 40px;
}
.main-search-input:before{display:none}
.homflat{
  min-height:400px;
  .intro-item{
     min-height:10px;    padding-top: 30px;
  }
}
.bgreviews{
  background:#f7f0e4;
  border-radius:10px;
  padding:20px;
  h5{
    line-height: 33px;
    font-size: 25px;
  }
  .notereview{
        color: #4ec5ca;
    font-weight: bold;
    font-size: 30px;
  }
  .listing-rating i {
    font-size: 15px;
  }
  p{
   color:#4a6784
  }
}
.homebg{    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;}
.main-search-input-tabs {
    margin-top: 33px;
}
 padding-top: 60px;
   .li778{
        
    left: 22px;
    color: #FFF;
    font-weight: bold;
    letter-spacing: 1px;
    top: 11px;
   
    font-size: 14px;

   }
.tabs-menu{
  padding-left: 0%;
}
.hero-categories_title{font-size:18px}
.current a{
    border-radius: 21px !important;
    color: #FFF !important;
}
.current a.tain1{
     background: #F75C96;
}

.current a.tain2{
     background: #5ECFB1;
}

.current a.tain3{
     background: #65a4dd;
}

.current a.tain4{
     background: #cba741;
}
.particles-wrapper{padding:30px 0px;}
   .autocomplete-container{
     width: 100%;
     border-right: 0px;
   }
   .pd889{padding-top: 30px;padding-bottom: 30px;}
   .header-sec-link a{text-align:center}
   .hero-categories_title{display:block !important}
   .header-assistant-button {
   text-transform: uppercase;
   background: #069d75 !important;
    position: relative;
    height: 50px;
    line-height: 50px;
    border: none;
    color: #fff;
    border-radius: 30px;
    padding-left:20px;
    padding-right:20px;
    cursor: pointer;
    font-weight: 600;
   margin-bottom:25px
   
   }
   .listing-item{
     width: 100%;
   }
   .grid-item-holder{
     height:auto !important
   }
   h4{    text-transform: uppercase;}
 
}

  .process-wrap-home .process-item{
   border: none;
    background: #FFF;
    padding:0px;
    padding-bottom: 22px;

   }
    .process-wrap-home .process-item img{
      width:100%;
      min-height: 126px;

   }
    .process-wrap-home .process-item img.first{
      min-height: 126px;

   }
   .process-wrap-home .pr-dec{display:none}
   .pad665456{padding-bottom: 0px;}
  .modalhomexl990  .modal-header{border:none}
  .modalhomexl990  .modal-footer{border:none}

  .processhome990{
      width: 322px;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    clear: both;
     a{
         width: 100%;
         background: #384F95 !important;
         margin-top: 0px;
         color:#FFF

     }
  }
  .cadre {
      // width: 475px;
      // height: 64px;
      /* UI Properties */
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      margin: 0 auto;
  }
  .cadre span {
    float: left;
    width: 33%;
    padding: 10px 6px;
    /* UI Properties */
    color: var(--unnamed-color-073460);
    text-align: center;
    
    letter-spacing: 0px;
    color: #073460;
    opacity: 1;
    font-size: 1rem;
    margin-top: 8px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .cadre span.active {
    /* UI Properties */
    background: #14A6E4 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #FFFFFF;
    border-radius: 5px;
  }

.fser778{
       display: flex;
    justify-content: center;
    align-items: center;
    color: #073460;
}
  .cadre1{
    width: 100%;
    min-height: 120px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 0 auto;
    margin-top: 10px;
        padding-bottom: 20px;
    .active{
     background: #14A6E4 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #FFFFFF;
    border-color:#14A6E4 !important;
     
    }
    hr{
         margin-top: 25px;
    margin-bottom: 9px;
    }
  }
  .cadre1 .form{padding: 20px;}
  .cadre1 select, .cadre1 input, #section-filtre-bien input, #section-filtre-bien select{
    width: 100%;
    height: 40px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    opacity: 1;

    /* UI Properties */
    text-align: left;
    
    letter-spacing: 0px;
    color: #73777B;
    opacity: 1;
    font-size: 14px;
    padding: 5px 10px;
  }
  .flexcol{
   display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .button_search{
    width: 136px;
    /* UI Properties */
    background: #14A6E4 0% 0% no-repeat padding-box !important;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    padding: 12px !important;
    letter-spacing: 0.6px;
  }
  .deposer-annonce{    padding: 16px 2px !important;
    letter-spacing: 0.6px;}
  .deposer-annonce:hover{background: #14A6E4 0% 0% no-repeat padding-box !important;}
  .position_relative{position: relative;}
  .type-bien{
    position: absolute;
        top: 16px;
    left: 16px;
    width: auto !important;
    background: rgba(255,255,255,0.7);
    padding: 3px;
    border-radius: 4px;
    font-size: 12px !important;
  }
  .img-favoris{
        position: absolute;
    top: 19px;
    right: 21px;
    width: 27px;
  }
  .priceoffre{
        font-size: 14px !important;
        color: #073460 !important ;
        span{

        }
  }
  .type_b{
    padding: 9px 5px;
    border: 2px solid #ececec;
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;
    color:#757575;
  }
  .process-wrap li h4.title , .process-item h4.title{
   text-align: left;
    font-size: 10px;
    text-transform: capitalize;
  }
  .process-item .priceoffre span {
    font-weight: bold;
}
  .process-wrap li h4.tag, .process-item h4.tag {
    text-align: center;
    text-transform: capitalize;
    float: left;
    width: 100%;
    margin-bottom: 12px;
    font-weight: 100;
    font-size: 9px !important;
    color: #aba9a9 !important;
    background: #FFF;
    padding-left: 10px;
    padding-right: 10px;
}

  .boxShadow{
    box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
    -webkit-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
    -moz-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);
  }

#section-filtre-bien{
    padding-top: 25px;
    padding-bottom: 25px;
.col-md-2{
  padding-left:4px;
  padding-right:4px;
}
.lfp333{padding-left:15px}
.lrp333{padding-right:15px}
}
.mtf09{
    margin-top: 14px !important;
}
#biens-results{
    padding-top: 0px;
    padding-bottom: 25px;
  .process-img {
    min-height: 127px
    }

}
#biens-detail-slide{
padding-top:20px;
.ps-banner--market-4 {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 250px;
    position: relative;
}
   .ps-banner_img{
       border-radius: 20px;
    position:relative;
    text-align:center;
        min-height: 460px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
      img{

         margin: 0 auto;
          max-height: 340px;

      }
  }

 .slick-dots {
    position: relative;
    display: block;
    bottom: -10px;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 190px;
    height: 120px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-thumb li img {
       max-width: 100%;
    padding: 0;
    height: 100%;
    border-radius: 20px;
}



}

#detail-maps{
     padding-top: 0px;
}

#biens-detail{
padding-top:0px;
h1{
   letter-spacing: 0px;
    color: #073460;
    opacity: 1;
    font-size: 20px;
}
h2{
   font-family: 'Montserrat-Medium';
    color: #073460;
    font-size: 15px;
    margin-bottom:30px;
}
h3{
   color: #073460;
    font-size: 19px;
    margin-bottom: 25px;
}
hr{
  color: #989898;
    margin-top: 31px;
    height: 1.3px;
    margin-bottom: 30px;
}
p{
   color:#5B5B5B
}
a.btndall{
  color: #073460;
    padding: 16px;
    border-radius: 10px;
    font-size: 13px;
    border: 1px solid #073460;
}
.blocreservation{
box-shadow: 0px 3px 20px #74747433;
padding:20px;
border-radius: 10px;
a{
  width: 100%;
    border-radius: 5px;
    margin-top: 10px;
}
h6{
  color: #797979;
  font-size: 13px;
}
strong{
  color: #454647;
  font-size: 19px;
}
.cdr990{
    margin-bottom: 6px;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    padding: 10px;
    .tjst6{
    justify-content: space-between;
    }
    div{
      display: flex;
      align-items: center;
      margin-bottom: 1px;
     span{
      color:#7a7a7a;
      font-size: 13px;
      padding-right: 20px;
     }
     b{
      font-size:12px;
      color:#454647;
     }
     strong{
          font-size: 17px;

     }
   
    }
      hr{
      margin-top: 13px !important;
      margin-bottom: 9px !important;
     }

}
}
ul{
    padding: 0px;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    width: 70%;
  li{
    list-style: none;
    background-image: url('../../../public/images/equipemenrts2.png');
    background-repeat: no-repeat;
    padding-left: 55px;
    line-height: 30px;
    background-size: 41px;
     height: 30px;
     margin-bottom: 10px;

  }
  li.t1{
    background-position: 1px -1px;
  }
    li.t2{
    background-position: 1px -195px;
  }
    li.t3{
    background-position: 1px -42px;
  }
    li.t4{
    background-position: 1px -234px;
  }
    li.t5{
    background-position: 1px -77px;
  }
    li.t6{
    background-position: 1px -271px;
  }
    li.t7{
    background-position: 1px -120px;
  }
     li.t8{
    background-position: 1px -313px;
  }
     li.t9{
    background-position: 1px -159px;
  }
  li.t10{
    background-position: 1px -351px;
  }
  li.t11{
    background-position: 1px -380px;
  }
  li.t12{
    background-position: 1px -380px;
  }
  li.t13{
    background-position: 1px -380px;
  }
  li.t14{
    background-position: 1px -380px;
  }
  li.t15{
    background-position: 1px -380px;
  }
  li.t16{
    background-position: 1px -380px;
  }
  li.t17{
    background-position: 1px -380px;
  }
  li.t18{
    background-position: 1px -380px;
  }
  li.t19{
    background-position: 1px -380px;
  }
  li.t20{
    background-position: 1px -380px;
  }


}

}

#biens-reservation{
padding-top:50px;
.bloc8876{
      background: #FFF;
    border-radius: 10px;
    margin-top: 28px;
     padding: 34px;
}
h1{
    color: #073460;
    font-size: 29px;
}
.bloc889{
 
}
.card {
        background-color: white;
        padding: 10px 20px 11px;
        border-radius: 5px;
        width: 100%;
        border: none;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
      }
      .pay-button{
        padding: 0.7rem 2rem;
        width: max-content;
        margin: 1rem 0;
        color: white;
        font-weight: bold;
        font-size: medium;
        background-color: #14A6E4;
        border: 0;
        border-radius: 5px;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        transition: box-shadow 500ms;
        cursor: pointer;
      }
      .pay-button:disabled{
        background-color: #afafaf;
        box-shadow: none;
        cursor: default;
      }
      .pay-button:disabled:hover{
        box-shadow: none;
      }
      .pay-button:hover{
        box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
      }
    .payimages{
      text-align:center;
      with:100%;
      img{
           width: 80%;
    margin: 0 auto;
      }
    }
hr{     color: #ccc;
    margin-top: 30px;
    margin-bottom: 30px;}
h4{
   color:#073460;
   font-size: 18px;
    margin-bottom: 22px;
    text-align: left;
    width: 100%;
}
p{
  text-align: left;
    width: 100%;
}
h2{
  color: #454647;
    font-size: 17px;
}
h3{
    font-family: 'Montserrat-Medium';
    color: #454647;
    font-size: 13px;
    margin-bottom: 30px;
}
.flx00987{
margin-bottom: 6px;
display: flex;
    align-items: center;
  img{
   width: 20px;
   margin-right: 8px;
  }
  span{
    color: #898989;
    min-width: 93px;
  }
  b{
    color: #646464;
    font-size: 13px;

  }
}
.blocd8876{
   border: 1px solid #d6d6d6;
    border-radius: 12px;
    min-height: 140px;
    overflow: hidden;
  .b8876{
       background-position: center;
       background-size: cover;
  }
}
 div.bl99IU7{
   padding: 16px 25px;
      h6{
        color: #797979;
        font-size: 13px;
        strong{
         color:#073460;
         font-size: 20px;
        }
      }

    .dv77{
     div{
         display: flex;
      align-items: center;
      margin-bottom: 1px;

     }
      .tjst6{
    justify-content: space-between;
    }
     
      }
     
     span{
      color:#7a7a7a;
      font-size: 13px;
      padding-right: 20px;
     }
     b{
      font-size:12px;
      color:#454647;
     }
     strong{
          font-size: 17px;

     }
   
    }
}
.putselectform{
   position:relative;
   background-image:url('../../../public/images/icoselectpuce.png');
   background-repeat: no-repeat;
    background-size: 25px;
    background-position: right;
    cursor: pointer;
        display: flex;
    align-items: center;
   width: 100%;
    height: 40px;
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    opacity: 1;
    text-align: left;
    letter-spacing: 0px;
    color: #73777B;
    opacity: 1;
    font-size: 12px;
    padding: 5px 10px;
    span{

    }


}


.selectvoyageurs{
box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
   position: absolute;
    left: -0px;
    top: 44px;
    background: #FFF;
    z-index: 999;
    border-radius: 10px;
    padding: 10px;
    width: 100%;

    ul{
    padding: 0px;
    margin: 0px;
     li{
     list-style:none;
     display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid #eee;

     }
     div{

     }

     span{
     color:#797979;
     font-size:10px;
     text-align: left;
     b{
       color:#797979;
       display: block;
     }

     }
    }


}


  .DateRangePicker{
  box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
   position: absolute;
    left: -241px;
    top: 44px;
    background: #FFF;
    z-index: 999;
    border-radius: 10px;
    padding: 10px;


    .tt566{
      
     .ttbg77{
       background:#D9D9D9;
       padding:10px;
       border-radius:10px;
       width:max-content;
       margin: 0 auto;
       display: flex;
    align-items: center;
    justify-content: space-between;
       span{
            width: 142px;
            display: block;
            border-radius: 10px;
            color:#073460;
            padding: 5px;
            cursor:pointer;
       }
       span.active{
         background:#073460;
         color:#FFF;
       
       }
     }
     .ttbg78{
       display: flex;
    align-items: center;
    width: max-content;
    margin: 0 auto;
    margin-top: 10px;

      span{
         padding: 3px 5px;
    width: 70px;
    border: 1px solid #73777B;
    border-radius: 30px;
    font-size: 12px;
    color: #73777B;
     cursor:pointer;
         margin-left: 3px;
    margin-right: 3px;
     display: flex;
    align-items: center;
    justify-content: space-around;

      }
      span.active{
        background:#073460;
         color:#FFF;
         border: 1px solid #073460;
      }
      b{
      
       div{
         font-size: 11px;
          line-height: 5px;
       }
      }
     }

    }



    .rdrDefinedRangesWrapper,.rdrMonthAndYearPickers,.rdrDateDisplayWrapper{
     display:none;
    }
    .rdrMonthAndYearWrapper {

    height: 36px;
  
     }
     .rdrMonthName{
      text-align: center;
      color: #073460;
     }
     .rdrNextPrevButton{
       background: #073460;
       border-radius: 15px;
       color: #FFF;
     }
     .rdrPprevButton i {
    
    border-color: transparent #ffffff transparent transparent;
     
  }
  .rdrNextButton i{
    border-color: transparent transparent transparent #FFF;
  }
  }
  .modelhermixtarif{
    h1{
         color: #073460;
    font-size: 18px;
    text-align: center;
    width: 100%;

    }
    p{
          margin-bottom: 7px;
    font-size: 13px;
    }
    strong{
     color: #073460;
    }
    .ff8898{ display: flex;
    align-items: center;
    justify-content: flex-start;}
    .form-check{
     margin-right:10px;
    }
  }
@media only screen and (max-width: 1024px) {
    .home {


    }
}

.z-index-99 {
  z-index: 9999 !important;
}