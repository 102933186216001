@import "../../assets/sass/colors";
.single {
   .par-elem{
      transform: translateZ(0px) translateY(10.6918%);
   }
   .ps998{    position: relative;
    height: 100%;
    text-align: center;
    width: 200px;
    margin: 0 auto;}
   .ps889{
     position: absolute;
    width: 150px;
    height: 150px;
    top: 111px;
    border-radius: 100px;
    z-index: 99999;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
   }
    .tain1{
     background: #F75C96;
}
.ps9988{
  width: 60px;
    height: 60px;
    float: left;
    border-radius: 100%;
        background-position: center;
    background-size: cover;
}

 .tain2{
     background: #5ECFB1;
}

 .tain3{
     background: #65a4dd;
}

.tain4{
     background: #cba741;
}
   .tags89 a{background: #EEE;
    padding: 8px;
    border-radius: 30px;}
   .mrg88{margin-right:10px}
   .price{
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 1px;
    background: #088b68;
    padding-left: 25px;
    padding-right: 25px;
   }
   .missions{
          font-weight: bold;
    font-size: 13px;
    letter-spacing: 1px;
    padding-left: 32px;
    padding-right: 34px;
   }
   .return{
              position: absolute;
    z-index: 9999;
    left: 116px;
    top: -87%;
   }
.selected{
  font-weight: bold;
    font-size: 13px;
    letter-spacing: 1px;
    background: #384f95;
    padding-left: 25px;
    cursor: pointer;
    padding-right: 25px;
}
   
}

@media only screen and (max-width: 1024px) {
    .single {
    }
}
