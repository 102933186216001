@import 'colors';

.btn{
    padding: 12px 14px;
    font-family: 'MontserratSemiBold',sans-serif;
    font-size: 14px;
    border-radius: 10px;
    transition: all 500ms;
    color: white;
    &:focus,&:active,&:hover{
        box-shadow: none;
    color: white;
    }
    &.btn-primary:hover{
        background-color: $primary;
        border-color: $primary;
        box-shadow: 0 0 20px 8px $primary-transparent;
        transform: scale(1.02);
    }
    &.btn-dark:hover{
        box-shadow: 0 0 20px 8px $dark-transparent;
        transform: scale(1.02);
    }
    &.btn-green{
        background-color: $green;
        border-color: $green;
        color: white;
        &:hover{
            box-shadow: 0 0 20px 8px $green-transparent;
            transform: scale(1.02);
        }
    }
}