@import "../../../../assets/sass/colors";
.coiffure3{
    
  .boxShadow{
    box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
    -webkit-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
    -moz-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);
  }
  .coiffurebox{
    background: #fff;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    cursor: pointer !important;
  }
  .coiffurebox p{
    color: rgb(7, 52, 96);
    margin-top: 10px;
    margin-bottom: 0;
  }

  .coiffureQte{
    background: #fff;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    // text-align: center;
    padding: 10px;
    margin-top: 5px;
  }
  .coiffureQte p{
    color: rgb(7, 52, 96);
    font-size: 10px;
    margin-bottom: 0;
  }
  .selected{
    background: rgba(78, 197, 202, 0.2);
  }

  .span{
    float: right;
    text-align: center;
  }
  .span_cercle{
    border-radius: 10px;
    border: 1px solid #cecdcd;
    background: #cecdcd;
    color:#fff;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .s_qte{
    font-size: 15px;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: -3px;
  }
  .s_plus{background: rgb(7, 52, 96);}


  .listeservice{
    background: #fff;
    width: 90%;
    margin: 0 auto;
    text-align: left;
    padding: 10px !important;
    cursor: pointer !important;
    margin-top: 20px;
    margin-left: 10px;
    border-radius: 5px;
  }

  .divservice{
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #d3cece;
      margin-right: 5px !important;
      margin-left: 5px !important;
      color: rgb(7, 52, 96);
  }
  .divservice input{    margin-bottom: 0 !important;margin-right: 5px;}
  .colordevis{font-size: 16px;color:#4ec5ca}
}