@import "../../../assets/sass/colors";
.fondation{
      border: 1px solid #CCC;
    padding: 10px;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 18px;
}
.paiments {
  
.ps889 {
  
    width: 85px;
    height: 85px;
    top: 7px;
    border-radius: 50px;
    z-index: 99999;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}

.libebaree{
text-decoration: line-through;
}

  .button-appliquer{
        padding: 7px 14px !important
     }
     .hide-div{
         display:none;
     }
     .vide-phrase{
        color: #ff914c;
     }
     .prix-barre{
        text-decoration: line-through;
     }
     .card {
        background-color: white;
        padding: 10px 20px 11px;
        border-radius: 5px;
        width: 100%;
        border: 1px solid #ff914c;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
      }
      .pay-button{
        padding: 0.7rem 2rem;
        width: 100%;
        margin: 1rem 0;
        color: white;
        font-weight: bold;
        font-size: medium;
        background-color: #2e3f6e;
        border: 0;
        border-radius: 5px;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        transition: box-shadow 500ms;
        cursor: pointer;
      }
      .pay-button:disabled{
        background-color: #afafaf;
        box-shadow: none;
        cursor: default;
      }
      .pay-button:disabled:hover{
        box-shadow: none;
      }
      .pay-button:hover{
        box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
      }
    form {
        border: solid 0.3px #259eef;
        background-color: white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 5px;
        input {
            border: none;
            border-radius: 0;
            background: transparent;
            min-width: 300px;
        }
        .btn {
            font-size: 14px;
        }
        .table-info-panier {
            padding: 30px;
            margin-top: 30px;
        }

       
    }



        .rapelasst{
           padding: 20px;
           overflow: hidden;
           border-bottom: 1px solid #e5e7f2;
             img{
                width: 60px;
			    height: 60px;
			    border-radius: 50px;
			    float: right;
             }
             strong{
                   margin-top: 10px;
                   display: block;
             }
        }
        .rapelasstbtm{
            border: 1px solid #e5e7f2;
		    background: #f9f9f9;
		    margin: 19px;
		    padding: 15px 20px 0px 20px;
		    border-radius: 4px;
		    color: #7d93b2;
		    font-size: 12px;
		    -webkit-appearance: none;
		    outline: none;
		    overflow: hidden;
		    z-index: 1;
           .group{
             margin-bottom: 13px;
                i{
                   padding-right: 12px;
                   font-size: 14px;
                       color: #4DB7FE;
                }
           }
        }

}

@media only screen and (max-width: 1024px) {
    
}
