@import "../../../assets/sass/colors";
.besoin {
    
    .log-separator span{
          font-weight: bold;
    font-size: 20px;
    width: 63px;
        border: 1px solid #f6f6f6;
        color: #073460;
    }
       h3{
      color:#073460;
      font-size: 20px;

    }
    .btngreen{
       background: #4ec5ca !important;
    border-radius: 30px;
    }
    .progressbare{
    margin-top: 10px;
    margin-bottom: 40px;
    width: 95%;
    float: left;
    height: 7px;
    border-radius: 8px;
    background: #e8e8e8;
    position: relative;
    .progressvalue{
       background: #e99f28;
 
    height: 6px;
    border-radius: 10px;
    }
    .veh{
      position: absolute;
        top: -23px;
       
      img{
      width: 45px;
      }
    }
    .bat{
         width: 40px;
    position: absolute;
           right: -41px;
    top: -33px;
    }

    }
    .section-title{
      h2{text-align:left}
      p{text-align: left;
    margin-left: 0px;
    color: #073460;
    font-weight: bold;
    font-size: 15px;}
    }
    .separet{
           height: 1px;
    clear: both;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    margin-top: 15px;
    margin-bottom: 25px;
    }

  .mrg78{
    margin-bottom:30px;
        clear: both;
    }
    ul.liser{
      margin:0px;
      margin-top:10px;
      padding:0px;
      clear: both;
          overflow: hidden;


  li{
    list-style: none;
    float: left;
    background: #FFF;
    padding: 10px 2px;
    color: #073460;
    width: 170px;
    font-size: 15px;
    text-align: center;
    border-radius: 7px;
    margin-right:10px;
    cursor:pointer;
    margin-bottom:20px;
      box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.46);
      -webkit-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.46);
      -moz-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.46);
      }
      li.active{
           background: #e99f28;
          color: #FFF;
      }
    }
   .sect2{
   background:#FFF;
   padding-top: 50px;
    padding-bottom: 50px;
 
    p{
     font-weight: normal;
     margin-top:15px;
    }

   }

   .cadrbs1{

    overflow: hidden;
    background: #f7eee0;
    padding: 0px;
    border-radius: 20px;
    ul{
      .process-item{
        background:none;
        p{    font-size: 13px;}
            
      }
      .process-img{
           min-height: 100px;
      } 
      img{
       max-height:100px
      }
    }
  
   }


}

  .autocomplete-dropdown-container{
     background:#FFF;
     padding:10px;
     .listitem{
       margin-bottom:10px;
       cursor:pointer;
     }
   }

@media only screen and (max-width: 1024px) {
    .besoin1 {
        
    }
}
