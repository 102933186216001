@import "../../assets/sass/colors";

.is-sticky{
     box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
      -webkit-box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
       -moz-box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
}
.main-header {

.tolt::after {
   border-radius: 21px;
    background: #e5e5e5;
    color: #383838;
    content: attr(data-tooltip);
    font-size: 12px;
    font-family: arial;
    font-weight: bold;
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
    box-sizing: content-box;
}
.hotline{
           width: auto;
    float: left
}
.side-function-wrap{
    position: absolute;
       right: 16px;
    bottom: 17px;
}
.side-function{margin:0}
.side-function li {
    width: 40px;
    height: 40px;
    list-style: none;
}
 .show-help-center  {
        position: relative;
   margin-left:10px
     
}

.hotlinenumber{    width: max-content;
    position: relative;
    float: right;
    top: 2px;
    right: -4px;
    color: #e99f28;
    font-size: 13px;}
.main-search-button_2 i{
    padding-left:0px;
    font-size:30px;
    color:#FFF
}
.hide-help-center{display:none}
.iconfont {
 
}
 
  
}

@media only screen and (max-width: 1024px) {
    .header {
    }
  
 
}
