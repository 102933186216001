@import "../../assets/sass/colors";
.faq {
 .center{text-align:center}
  
}

@media only screen and (max-width: 1024px) {
    .register {
        form {
            padding: 15px 20px;
        }
    }
}
