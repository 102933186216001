@import "../../../assets/sass/colors";

 
 .locataire{

  .cdr009{
    box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
padding: 20px;
    border-radius: 5px;
  }

 }
@media only screen and (max-width: 1024px) {
    
}
