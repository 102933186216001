@import "../../../assets/sass/colors";
#besoin2 {
    
     textarea{
      background:#f1f1f1
     }
     .para{
     text-align: right;
    padding-top: 12px;
    overflow: hidden;
    clear: both;
     }
}

@media only screen and (max-width: 1024px) {
    #besoin2 {
        
    }
}
