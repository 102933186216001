@import "../../../assets/sass/colors";
.login {
  .mg889{
     margin-top:100px
  }
  .upcase{text-transform:uppercase}
  .nopadd{padding-bottom:0px}

}

@media only screen and (max-width: 1024px) {
    
}
