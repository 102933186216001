@import "../../../assets/sass/colors";

 
 .rapportestreint{

 .form-check-input{
       margin-top: 8px;
 }
  .form-check{
       margin-top: 5px;
 }
 .addnew{
  img{
   
  }
 }

  label{
   color:#073460;
   font-size:13px;
       padding-top: 6px;
  }
  input{
   border: 1px solid #D6D6D6;
}
h3{
      font-size: 14px;
    letter-spacing: -0.5px;
    color: #07345F;
    margin: 0;
    margin-top:40px;
    margin-bottom:10px;
    border: 0px;
}
.flx9980{

     display: flex;
    align-items: center;
    margin-top: 47px;
    justify-content: space-between;
    a{
          border: 1px solid #2e3f6e;
    padding: 7px;
    border-radius: 7px;
    font-weight: bold;
    }
}

 }
@media only screen and (max-width: 1024px) {
    
}
