@import "../../../../assets/sass/colors";
.coursStandard4{
  .boxShadow{
    box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
    -webkit-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
    -moz-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);
  }
  
  .header1{
    width: 90%;
    margin: 0px auto;
    height: 40px;
    background: rgb(7, 52, 96);
    color: #fff;
    text-align: center;
    padding: 10px;
    font-weight: bold;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  } 
  .body1{
    width: 90%;
    margin: 0px auto;
    min-height: 100px;
    color: rgb(7, 52, 96);
    background: #fff;
    padding: 10px;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .footer1{
    
    width: 90%;
    margin: 0px auto;
    height: 40px;
    background: #5bc8cd;
    color: #fff;
    text-align: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  #adresse{padding: 10px !important;}
  .btn{
    text-align: center;
    padding: 10px !important;
  }
  
  select{
    float: left;
      border: 1px solid #e5e7f2;
      background: #FFF;
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      color: #7d93b2;
      font-size: 12px;
      outline: none;
      z-index: 1;
      margin-bottom: 20px !important;
  }
  .blocfrequence{
    cursor: pointer;
    border-radius: 6px; 
    background: #FFF;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 20%;
    text-align: center;
    padding-left: 10px !important;
  }
  .p1{
    font-size: 22px;
    margin-bottom: 0;
    color: rgb(7, 52, 96);
    font-weight: bold;
  }
  .p2{
    font-size: 15px;
    margin-bottom: 0;
    color:#4ec5ca
  }
  .blocfrequenceActive{
    background: rgba(78, 197, 202, 0.2);
  }
  
  .blocfrequence:hover{background: rgba(78, 197, 202, 0.2);}
  
  .heure{
    text-align: center;
    background: #f9f9f9;
    margin-bottom: 10px;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .herueSelected{background: rgba(78, 197, 202, 0.2);}
  .section-title h2{text-align: left;}
}