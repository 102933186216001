@import "../../assets/sass/colors";
.login {
     
    display: flex;
    align-items: center;
    .color2-bg {
       background: #073460 !important;
    padding-right: 0px !important;
    width: 100%;
    border-radius: 6px;
    font-weight: normal;
    font-size: 18px;

}

.displypass{ 
 
 position: relative;
    float: left;
    width: 100%;
    clear: both;

 a{
       position: absolute;
    top: 10px;
    right: 10px;
 }
 }
.main-register-holder .tab-content{
    padding-top:0px;
}
.filter-tags label {
    font-size: 11px !important;
    color: #5b5b5b !important;
    font-weight: normal;
    float: left;
    padding: 0;
    width: 338px;
    padding-left: 10px;
    margin-bottom:20px
}
.actionename{
    ul{
    margin:0px;
    padding:0px;
      li{
         list-style:none;
         float:left;
         width: 50%;
         a{
             display: block;
            color: #5b5b5b;
            border: 1px solid #979a9e;
            border-radius: 5px;
            padding: 3px 10px;
            text-align: center;
         }
         a.active{
          background:#073460;
          color:#FFF !important
         }
         .mrright{margin-right:10px}
         .mrleft{margin-left:10px}
      }
    }
}
.main-register-holder .tabs-menu li.current{
    border-color:#073460
}
.main-register-holder .tabs-menu li a {
    color: #073460;
 
}
.lost_password a{
        color: #000;
    font-weight: normal;
}
.main-register{
     box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    padding: 6px 0 20px;
}
    .main-register_title {
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    text-align: center;
    line-height: 22px;
    padding-top: 10px;
    color: #fff;
    padding-left: 0;
    padding-bottom: 10px;
    background: #4E65A3;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    z-index: 2;
}
   
   .googleconnect{
      width: 100%;
    background-color: #FFF!important;
    border-radius: 7px !important;
    border: 0 !important;
    font-weight: normal !important;
    color: #262626 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 13px !important;
    font-family: 'Montserrat-Medium' !important;
    border: 1px solid #262626!important;
    text-transform: unset;
         background-color: #FFF !important;
    padding-left: 0px;
        margin-top: 12px !important;
            text-transform: unset;
      background-image:url('../../../public/images/icones/google.png') !important;
        background-repeat: no-repeat !important;
    background-position: 46px !important;
    background-size: 20px !important;
   }
   .download{
        float: left;
    border: 1px solid #e5e7f2;
    background: #FFF;
    width: 100%;
    padding: 10px 20px 10px 20px;
    border-radius: 4px;
    color: #919191;
    font-size: 12px;
    -webkit-appearance: none;
    outline: none;
    overflow: hidden;
    z-index: 1;
    height: 39px;
    margin-bottom: 22px;
    cursor: pointer;
    position: relative;
    input{
    display:none;

    }
    img{
     position: absolute;
    top: 9px;
    width: 16px;
    right: 10px;
    }
   }

   .pdpad{
     padding-top: 8px;
    padding-bottom: 8px;

   }
}

  .autocomplete-dropdown-container2{
     background:#FFF;
     padding:10px;
       box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
     .listitem{
       margin-bottom:10px;
     }
   }


@media only screen and (max-width: 1024px) {
    .login {
        
    }
}

.resend-code {
    margin-left: auto;
    font-size: 11px;
    cursor: pointer;
    a {
        color: grey !important;
    }
}
.react-tel-input .form-control {
    width: 100% !important;
}