@import "../../assets/sass/colors";
.product-item {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.01);
    border-radius: 10px;
    overflow: hidden;
    transition:500ms ;
    display: block;
    min-height: 170px;
    border: 2px solid white;
    .image{
         
        background-color: $grey2;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 10px;
        height:190px

    }
    .center{text-align:center;padding:10px}
    
    .info{
        width: 100%;
        padding: 8px 15px;
        position: relative;
        justify-content: space-between;
         
        h5{
            text-align:center;
            align-items:center;
            justify-content: space-between;
            font-size: 15px;
            margin-bottom: 15px;
          
        }
          span{
                background-color: $primary;
                border-radius: 10px;
                font-size: 15px;
                color: white;
                padding: 3px 10px ;
                margin-left: auto;
                font-family: 'Montserrat-Medium',sans-serif;
            }
        .address{
            margin-bottom: 10px;
        }
        .btn{
            padding: 4px 20px;
        }


    }
}

@media only screen and (max-width: 1024px) {
    .product-item {
    }
}
