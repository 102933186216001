@import "../../../../assets/sass/colors";
.gardeanimaux2{
.boxShadow{
  box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
  -webkit-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
  -moz-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);
}
.coiffurebox{
  background: #fff;
  border-radius: 10px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.coiffurebox p{
  color: rgb(7, 52, 96);
  margin-top: 10px;
  margin-bottom: 0;
}

.animauxQte{
  background: #fff;
  border-radius: 10px;
  width: 90%;
  margin: 0 auto;
  // text-align: center;
  padding: 10px;
  margin-top: 5px;
}
.animauxQte p{
  color: rgb(7, 52, 96);
  font-size: 10px;
  margin-bottom: 0;
}
.selected{
  background: rgba(78, 197, 202, 0.2);
}

.span{
  float: right !important;
  text-align: center !important;
}
.span_cercle{
  border-radius: 10px !important;
  border: 1px solid #cecdcd !important;
  background: #cecdcd !important;
  color:#fff !important;
  width: 16px !important;
  height: 16px !important;
  cursor: pointer !important;
}
.s_qte{
  font-size: 15px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  margin-top: -3px !important;
}
.s_plus{background: rgb(7, 52, 96);}
}