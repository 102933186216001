@import "../../assets/sass/colors";
.kep-login-facebook{
      width: 100%;
    background-color: #FFF!important;
    border-radius: 7px !important;
    border: 0 !important;
    font-weight: normal !important;
    color: #262626 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 13px !important;
    font-family: 'Montserrat-Medium' !important;
    border: 1px solid #262626!important;
    text-transform: unset !important;
    background-image:url('../../../public/images/icones/facebook.png');
        background-repeat: no-repeat;
    background-position: 46px;
    background-size: 20px;
}
footer {
.ff56{
        margin-bottom: 0px;
    padding-top: 6px;
}
.footerborder{
    box-shadow: -1px 0px 5px 0px rgba(0,0,0,0.84);
-webkit-box-shadow: -1px 0px 5px 0px rgba(0,0,0,0.84);
-moz-box-shadow: -1px 0px 5px 0px rgba(0,0,0,0.84);
    width: 100%;
    height: 2px;
}
.clearfix2{
    margin-bottom: 6px;
    float: left;
    span{
        padding: 0px 2px;
    }
}
.footer-logo img {
    width: auto;
    height: 100px;
}
.no-list-style{padding:0px}
.footer-widget-posts a{
  color:#4a6784;
      width: 100%;
    display: block;
    margin-bottom: 10px;
    font-size:12px;
}
.footer-social {    margin: 0 auto;
    text-align: center;
    width: 251px;    float: none;}
.nop66{padding-left: 0px;}
.color2-bg{
	      background: #384F95 !important;
	      padding-right: 0px !important;
    width: 100%;
    text-transform: uppercase;
}
}

@media only screen and (max-width: 1024px) {
    footer {
    }
}

#toTop {position: fixed;display: none;text-decoration: none;bottom: 35px;right: 10px;overflow: hidden;width: 60px;height: 60px;border: none;text-indent: 100%;background: url(../../../public/images/totop@2x.png) no-repeat right bottom;z-index: 9999;text-indent: -9999px;background-size: 100%;}
.is-top{display:block !important;-webkit-transition:all 0.3s linear; -moz-transition:all 0.3s linear; -o-transition:all 0.3s linear; -ms-transition:all 0.3s linear; transition:all 0.3s linear; }
  #toTop:before { content: ""; display: block; position: absolute; width: 0; height: 0; left: 0; right: 0; margin: 0 auto; top: 50%; transform: translateY(-50%); -moz-transform: translateY(-50%); -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); background: #fff; border-radius: 50%; -webkit-transition:all 0.3s linear; -moz-transition:all 0.3s linear; -o-transition:all 0.3s linear; -ms-transition:all 0.3s linear; transition:all 0.3s linear; }
  #toTop:hover:before { width: 45px; height: 45px; }
  
  #toTop:after{ content: ""; display: block; position: absolute; width: 25px; height: 50px; left: 0; right: 0; margin: 0 auto; top: 50%; transform: translateY(-50%); -moz-transform: translateY(-50%); -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); background: url(../../../public/images/totop-after.png) no-repeat center; border-radius: 50%; -webkit-transition:all 0.3s linear; -moz-transition:all 0.3s linear; -o-transition:all 0.3s linear; -ms-transition:all 0.3s linear; transition:all 0.3s linear; background-size: 0; }
  #toTop:hover:after { background-size: 50%; -webkit-transition-delay: 0.3s; transition-delay: 0.3s; }
  
  #toTop:active, #toTop:focus { outline:none; }


  #cookie-alert-container {
    padding-left: 25px;
    padding-right: 25px;
    position: fixed;
    width: 100%;
    bottom: 10px;
    color: #fff;
    text-align: center;
    z-index: 99999;
}
.cookie {
    padding: 15px 25px;
    max-width: 100%;
    width: 85%;
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    position: fixed;
    display: block;
    left: 0;
    right: 0;
    bottom: 25px;
    z-index: 99999;
    box-shadow: 1px 1px 4px #807e7e;
}
.cookie h3 {
    max-width: 100%;
    text-align: left;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 800;
    color: #4a4a4a;
}
.cookie .description {
    display: flex;
    flex-direction: column;
}
.cookie p {
    text-align: left;
    color: #333;
}
.cookie .description .form-group {
    display: flex;
    padding-left: 0;
}
.cookie button.darkbtn {
    background: #333;
    color: #fff;
}
.cookie button {
    margin-right: 15px;
    color: #333;
    border: 1px solid #333;
    border-radius: 10px;
    padding: 13px 23px;
    cursor: pointer;
}


