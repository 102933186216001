@import "../../../assets/sass/colors";

.portfeuile{
h4{
     color: #073460;
    font-weight: bold;
    letter-spacing: -0.4px;
    font-size: 15px;
}
.mtgt6{
   margin-bottom:20px;
}
 .flx998{
       display: flex;
    justify-content: space-around;
    align-items: center;
   .item{
    border-radius: 10px;
  padding: 12px 14px;
    min-width: 178px;
    p{
          margin: 0px;
    margin-bottom: 4px;
    color: #FFF;
    font-size: 13px;

    }
    strong{
         font-size: 20px;
    color: #FFF;
    }

   }
   .bg1{
    background:#14a6e4;
   }
    .bg2{
    background:#4ec5ca;
   }
    .bg3{
    background:#e99f28;
   }
    .bg4{
    background:#073460;
   }
    .bg5{
    background:#1747ee;
   }
  
 }
 .flx999{
     display: flex;
    align-items: center;
    justify-content: flex-start;
    img{
     margin-right: 17px;
    }
 h6{
  color: #073460;
    font-weight: bold;
    letter-spacing: -0.4px;
    font-size: 14px;
 }
  p{
   color: #073460;
    font-size: 12px;
    margin-bottom: 1px;

  }
  .bg99{ 
   background: #e5f6f7;
    padding: 8px 20px;
    font-size: 22px;
    border-radius: 10px;
    margin-left:5px;
  strong{
 color:#073460
  }

  }
 }
 .sepa66{
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
 }

 .row998{
 margin-top: 17px;
  p{
       display: flex;
    align-items: center;
   input{
       margin-right: 10px;

   }
   label{
     color: #5e7b97;
    font-size: 13px;
   }
  }
  table{
   tr{
    th{
          border: 1px solid #ccc;
    padding: 8px;
        color: #5e7b97;
    font-size: 13px;
    font-weight: normal;
    }
    td{
      border: 1px solid #ccc;
    padding: 8px;
        color: #5e7b97;
    font-size: 13px;
    font-weight: normal;
    }
   }
  }
 }
 .bt77{
      float: left !important;
    margin-top: 10px;
 }
 .download{
  .item1{
      color: #5e7b97;
    font-size: 13px;
        border-bottom: 2px solid #2e3f6e;
    padding-bottom: 7px;
    margin-top: 14px;
    margin-bottom:10px;
  } 
  .item{
      display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

  }
 }
 }
 


@media only screen and (max-width: 1024px) {
    
}
