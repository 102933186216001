@import "../../assets/sass/colors";
.myccount {


.rate{
     padding-bottom: 10px;
    width: 100px;
    margin: 0 auto;
}
  
 
    .dashboard-header{
      padding-bottom: 43px;
    }
    .dashboard-header_title h1{text-transform: capitalize;}
    .no-list-style{
           margin: 0;
    width: 100%;
    overflow: hidden;
    padding: 0;
    }
    .user-profile-menu li a span{
      background:#259eef
    }
    .main-dashboard-sec{background:#FFF}
    .dataTables_length{display:none}
    .dataTables_filter input{border: 1px solid #CCC;
    border-radius: 10px;
    padding: 3px;
    padding-left: 20px;}
    #datatable th{border-color: #dee2e6;}
    #datatable td{padding-top: 15px;padding-bottom:15px;vertical-align: middle;}
    .list-contr a{float:left; margin-right:10px;    width: 30px;
    height: 30px;
    line-height: 31px;
    color: #fff;
    float: left;
    position: relative;
    border-radius: 100%;
    margin: 0 4px;}

    .prfimg{
       width:30px;height:30px;border-radius: 20px;
    }
    .ico99{
      width: auto !important;
    border-radius: 4px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    }
    .dashboard-title:before{display:none}
    .htri1{
       border-right: 6px solid #F75C96;
       margin-bottom: 12px;
    }
    .htri2{
       border-right: 6px solid #5ECFB1;
       margin-bottom: 12px;
    }
    .htri3{
       border-right: 6px solid #4db7fe;
       margin-bottom: 12px;
    }
    .dataTables_paginate {display:none}

}

@media only screen and (max-width: 1024px) {
    .myccount {
        form {
            padding: 15px 20px;
        }
    }
}
