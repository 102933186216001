@import "../../../assets/sass/colors";
 fnbordernn{
    border: 0 !important;
    background:#FFF !important

  }
.editprofile {
 

}

@media only screen and (max-width: 1024px) {
    
}
