@import "../../assets/sass/colors";
.formules {
.row9998{
    position:relative
}
.row9998:after {
    content: " ";
    display: table;
    position: absolute;
    background: #CCC;
  height: 1px;
    width: 58%;
    top: 11px;
    left: 11%;
}
    
    .labelswt{
         display: flex;
    align-items: center;
    justify-content: end;
    }
     

.offerabonnement{
   padding-top:0px;
   .offerheader{
      .items{
            display: flex;
            align-items: center;
            justify-content: center;

         .item{
           min-width: 300px;
            text-align: center;
            background: #ededed;
            padding: 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            cursor:pointer;
            font-family: 'Montserrat-Medium';
            margin-left: 2px;
            margin-right: 2px;

         }
         .item.active{

            background: #073460;
            color:#FFF

         }

      }

   }

   .offerabbody{
         background: #073460;
         padding: 10px;

         .offeractio{
          background: #14A6E4;
    border: 0px;
    padding: 8px;
    width: 177px;
    margin: 0 auto;
    text-align: center;
    border-radius: 22px;
    color: #FFF;
         }

         .offeritems{

             .description{     color: #e0f0ff;
                            font-size: 9px;
                            margin-top: 20px;

                    p{
                         margin-bottom: 0px;
                    }

                }

            .itemsparticulaires{ 

               .item{
                  min-height: 549px;
               }

             }
       

            .items{
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 100%;

           
            .item{
               max-width: 25%;
    background: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-family: 'Montserrat-Medium';
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
                .title{
                    color: #646464;
                    text-align: center;
                    font-weight: bold;
                    padding-top: 10px;
                    font-size: 17px;
                    padding-bottom: 27px;

                }
                h5{
                 color: #323232;
                 text-align: center;
                 sup{
                   top: 0px;
                    font-weight: normal;
                    font-family: 'Montserrat-Medium';
                 }

                }
                p{
                  color: #686868;
                    font-size: 11px;
                    text-align: center;
                    padding-left: 15px;
                    padding-right: 15px;

                }
                ul{
                      margin: 0 0 15px;
                        min-height: 70px;
                        overflow: hidden;
                        padding: 0 0 0 8px;
                 li{
                          list-style: none;
                           display: flex;
                           align-items: flex-start;
                           font-size: 10px;
                            color: #454647;
                            display: flex;
                            align-items: flex-start;
                            margin-bottom: 5px;
                  img{

                  }
                  span{ 
                  padding-left: 10px;

                  }
                 }
                }
              }
            }
         }

   }
}
  
}

@media only screen and (max-width: 1024px) {
    .formules {
        form {
            padding: 15px 20px;
        }
    }
}
