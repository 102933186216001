@import "../../../assets/sass/colors";

 
 .abonnement{
  .cabonne{
    box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
   -webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
   -moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 10px;
    background: #FFF;
        overflow: hidden;
    .title{

    text-align: center;
    padding: 10px;
   
    color: #FFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    }
    .cr_classic{ background: #073460;}
    .cr_basique{ background: #e99f28 !important;}
    .cr_premuim{ background: #4ec5ca !important;}
    .cr_platinium{ background: #4886c8 !important;}
    .cr_libre{ background: #9fb2b3 !important;}
    .cntabn{
         padding-bottom: 22px;
    padding-top: 13px;
      h5{
             color: #646464;
            font-size: 15px;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 10px;
            sup{
                 font-weight: normal;
    font-family: 'Montserrat-Medium';
            }
      }
      ul{
      min-height: 70px;
            margin: 0px;
           padding: 0px;
           padding-left: 8px;
            overflow: hidden;

            margin-bottom:15px;
       li{
       list-style:none;
        span{
              font-size: 12px;
             letter-spacing: -1px;
             padding-left: 5px;
             color:#646464;
        }
        img{
              width: 14px;
        }
       }
      }
      button{
        border-radius: 20px;
    float: none !important;
    margin: 0 auto;
    width: 124px;
      }
    }
  }
  .inlineblk{
       width: 137px;
    display: inline-block;
  }
  .cstm09{
    a{
      border: 1px solid #07345F;
    padding: 8px;
    border-radius: 6px;
    color: #07345F;
    }
  }
  .imgprofil{
    text-align:center
  }
  .flx889bg{
   background: #d5ecee;
    padding: 3px 3px;
    border-radius: 4px;
    margin-bottom:8px;
  }
  .flx889{
     display: flex;
     align-items: center;
     justify-content: space-between;
     .item1{

     }
    .item2{
      .it1{
            background: #FFF !important;
            border-radius: 3px !important;
            color: #4ec5ca !important;
             padding: 2px 8px;
            font-size: 12px;
      }
      .it2{
            color: #646464 !important;
                font-size: 12px;
                 padding: 2px 8px;
      }
     }
  }
  .clrbl9{
        color: #073460;
         font-size: 11px;
        letter-spacing: -0.5px;
  }

  .MuiSlider-root {
    color: #4ec5ca;
    padding-bottom: 2px;
    }
    .flx881{
     margin-bottom:10px;
     overflow: hidden;
     label{
       margin-bottom:2px
     }
     input{ padding-left: 7px !important;}
     button{
      padding: 5px !important;
    border-radius: 4px;
    margin-top: 0px;
     }
    }
 }
 .profilImg{
  border-radius: 30px !important;
  height: 60px !important;
  width: 60px !important;
}
.rowtabcustomer{
    height: 240px;
    overflow: auto;
  th{
    color: #646464;
    font-family: 'Montserrat-Medium';
    font-size: 13px;
    font-weight: normal;
  }
  td{
    color: #073460;
    font-size: 12px;
  }
}
.ch667{
    padding-left: 12px !important;
}
.section298{
 padding-left: 8%;
 margin-bottom:20px;
 h4{
    font-size: 13px;
    color: #073460;
 }
 h6{
      font-size: 12px;
    color: #073460;
 }
 h7{
font-size: 12px;
    color: #5B5B5B;
    font-weight: bold;
        display: block;
    width: 100%;
    position: relative;
    overflow:hidden;
 }
  h7 span{
    border: 1px solid #d3d3d3;
    height: 1px;
    width: 100%;
    display: block;
    top: 11px;
    right: -100px;
    position: absolute;
    float: right;
 }
 .xtm00{
     text-align: right;
    margin-top: 6px;
    float: right;
    clear: both;
    color: #073460;
    font-size: 12px;
 }
 .inptxt{

 }
}
.section298frm{
    background-color: #fff;
    border-radius: 8px;
    padding:20px 10px;
    margin-bottom:15px;
    input[type="number"], input[type="date"] {
    float: left;
    border: 1px solid #e5e7f2;
    background: #FFF;
    width: 100%;
    padding: 10px 20px 10px 45px;
    border-radius: 4px;
    color: #7d93b2;
    font-size: 12px;
    -webkit-appearance: none;
    outline: none;
    overflow: hidden;
    z-index: 1;
}
.posrela{
   position: relative;
   span{
    position: absolute;
    top: 6px;
    font-weight: bold;
    color: #073460;
    font-size: 13px;
    right: 7px;
   }
}
ul{
padding: 0px;
margin:0px;
   li{
     list-style: none;
    background-image: url('../../../../public/images/icoservices.png');
    background-repeat: no-repeat;
     padding-left: 40px;
    line-height: 0px;
    background-size: 26px;
    height: 23px;
    margin-bottom: 10px;
        display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
      font-size: 12px;  
        
   }
   .t1 {
    background-position: 0px 2px;
    }
     .t2 {
    background-position: 0px -33px;
    }
     .t3 {
    background-position: 0px -67px;
    }
     .t4 {
    background-position: 0px -101px;
    }
     .t5 {
    background-position: 0px -130px;
    }
     .t6 {
    background-position: 0px -161px;
    }
    .t7{
    background-position: 0px -195px;
    }
    .t8{
    background-position: 0px -225px;
    }
       .t9{
    background-position: 0px -262px;
    }
       .t10{
    background-position: 0px -292px;
    }
       .t11{
    background-position: 0px -326px;
    }
       .t12{
    background-position: 0px -357px;
    }
       .t13{
    background-position: 0px -389px;
    }
       .t14{
    background-position: 0px -425px;
    }
          .t15{
    background-position: 0px -457px;
    }
          .t16{
    background-position: 0px -489px;
    }
          .t17{
    background-position: 0px -518px;
    }
          .t18{
    background-position: 0px -550px;
    }
          .t19{
    background-position: 0px -586px;
    }
          .t20{
    background-position: 0px -616px;
    }
            .t21{
    background-position: 0px -649px;
    }
            .t22{
    background-position: 0px -682px;
    }
            .t23{
    background-position: 0px -717px;
    }
            .t24{
    background-position: 0px -748px;
    }
            .t25{
    background-position: 0px -780px;
    }
}
}
.ingrouped{
   input{
         width: 65px !important;
        height: 25px !important;
        text-align: center !important;
   }
   .input-group .btn{
         margin: 0px;
    padding: 0px !important;
    width: 20px;
    height: 25px;
    background: #FFF;
    border-radius: 0px;
    border: 1px solid #e5e7f2;
    font-weight: normal;
    color: #073460;
    line-height: 10px;
   }
}
.bgcap89{
   .col-md-2 {
     div.img{
    background:url(../../../../public/images/Groupe7130.png);
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 57px;
    border: 1px dashed #073460;
    min-height: 95px;
    border-radius: 10px;
    margin-bottom:10px
     }
   }
}
.isnonbg{
       background-image: none !important;
    padding-left: 0px !important;
    color: #073460 !important;
    justify-content: flex-start !important
}
.google_search{
 position: absolute;
    z-index: 9999;
    top: 10px;
    left: 10px;
    width: 41%;
   .flx998{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    input{
      border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    }
    button{
border-radius: 5px;
    width: 31px;
    height: 31px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    i{
         color: #FFF;
    }
    }

   }
}
@media only screen and (max-width: 1024px) {
    
}
.bgcap89 .img{position: relative;}
.bgcap89 .inputFileInvis{
  background: transparent;
  opacity: 0;
  position: absolute;
  z-index: 99999;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.my-2{background: rgba(255,255,255,0.8);}