@import "../../assets/sass/colors";

.pagecontent {
   .mrgtop{
	margin-top:30px
}
}




@media only screen and (max-width: 1024px) {
    .listing {
    }
}
