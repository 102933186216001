@import "../../../assets/sass/colors";
.responses {
   .geodir-category-img-wrap img {
    height: 161px;
}

}

@media only screen and (max-width: 1024px) {
    
}
