@import "../../../assets/sass/colors";

  .wie778{
       border-radius: 9px;
    min-height: 90px;
    padding: 20px;
        margin-bottom: 22px;
   h6{
   color: #24609b;
    font-family: 'Montserrat-Medium';
    font-weight: normal;
    font-size: 15px;

   }
  strong{
        font-family: 'Montserrat-Bold';
    font-size: 20px;
    }
  }
  .wie778c1{
   background:#b9c7fa
  }
   .wie778c2{
     background:#caedef
  }
   .wie778c3{
      background:#b8e4f7
  }
   .wie778c4{
     background:#f8e1bc
  }
@media only screen and (max-width: 1024px) {
    
}
