@import "../../../../assets/sass/colors";
.jardinage2{
  .boxShadow{
    box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
    -webkit-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);;
    -moz-box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.46);
  }
  
  .header1{
    width: 90%;
    margin: 0px auto;
    height: 40px;
    background: rgb(7, 52, 96);
    color: #fff;
    text-align: center;
    padding: 10px;
    font-weight: bold;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  } 
  .body1{
    width: 90%;
    margin: 0px auto;
    min-height: 100px;
    color: rgb(7, 52, 96);
    background: #fff;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  #adresse{padding: 10px !important;}
  .btn{
    text-align: center;
    padding: 10px !important;
  }
  
  select{
    float: left;
      border: 1px solid #e5e7f2;
      background: #FFF;
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      color: #7d93b2;
      font-size: 12px;
      outline: none;
      z-index: 1;
      margin-bottom: 20px !important;
  }
  .blocNiveau{
    cursor: pointer;
    border-radius: 6px;
    background: #FFF;
    padding: 10px 20px;
    text-align: center;
    margin-right: 15px;
    width: auto !important;
    margin-bottom: 30px;
  }
  .blocNiveau .p1{
    font-size: 14px;
    margin-bottom: 0;
    color: rgb(7, 52, 96);
    font-weight: bold;
  }
  .blocNiveau:hover{
    background: rgba(78, 197, 202, 0.2);
  }
  .blocfrequenceActive{
    background: rgba(78, 197, 202, 0.2);
  }
  
  .span{
    float: inherit !important;
    text-align: center;
  }
  .span_cercle{
    border-radius: 30px;
    border: 1px solid #cecdcd;
    background: #cecdcd;
    color:#fff;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  // .s_qte{
  //   font-size: 20px !important;
  //   margin-left: 10px !important;
  //   margin-right: 10px !important;
  //   margin-top: -3px !important;
  // }
  .s_plus{background: rgb(7, 52, 96);}
  .section-title h2{text-align: left;}

}