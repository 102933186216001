@import "../../../assets/sass/colors";
.editprofil {
 .geodir_status_date{position:relative;text-align: center;}
 .geodir_status_date i{margin-left:10px;font-weight: bold;    font-size: 14px;}

    .inputFileInvis {
          background: transparent;
    opacity: 0;
    position: absolute;
    z-index: 99999;
    width: 100%;
    left: 0px;
    margin: 0;
    top: 0;
}
.labbnrd{    border-radius: 10px !important;
    color: #FFF;
    font-weight: bold;
    text-align: center;}
}

@media only screen and (max-width: 1024px) {
    
}
