@import "../../assets/sass/colors";
.assistant-item {
   a{cursor: pointer;}
   .mr88{margin-bottom:20px}
   .geodir-category-img-wrap{
     position:relative
   }
   .libebaree{text-decoration: line-through;}
   .upcase{
    text-transform: capitalize;
     span{
       text-transform: uppercase;
     }
   }
   .p33{width: 100px;
    margin: 0 auto;}
   .ps889{
    position: absolute;
    width: 100px;
    height: 100px;
    top: 7px;
    border-radius: 50px;
    z-index: 99999;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
   }
   .geodir_status_date {
    bottom: 34px;
    top: unset;
    }
    .view-profil-button {
    text-transform: uppercase;
    background: #59adf3 !important;
    position: relative;
    height: 42px;
    line-height: 42px;
    border: none;
    margin: 0 auto;
    font-size: 10px;
    color: #fff;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    font-weight: 600;
  }
  .chosir-profil-button{
     text-transform: uppercase;
   
    position: relative;
    height: 42px;
    line-height: 42px;
    border: none;
    margin: 0 auto;
    font-size: 10px;
    color: #fff;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    font-weight: 600;
  }
.geodir-category-footer{
    border-top:0px
}
    .price{
      font-weight: bold;
      font-family: 'Montserrat-Bold';
      letter-spacing: 1px;
      background:#02c390
    }
    .pricebb{
    font-weight: bold;
      font-family: 'Montserrat-Bold';
      letter-spacing: 1px;
      background:#2e3f6e
    }
    .geodir-category-content p{margin-bottom:0}
}

@media only screen and (max-width: 1024px) {
    .restau-item {
    }
}
