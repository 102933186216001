@import "../../../assets/sass/colors";
 .history{ 

   .historyttp{
   .rowflex{
         display: flex;
    justify-content: center;
    align-items: center;
    strong{
     font-size: 19px !important
    }
    img{
     margin-right:10px;
     width: 50px;
    }
   }
     strong{
       font-size: 25px;
    letter-spacing: -0.5px;
    color: #07345F;
     }
     h3{
       font-size: 16px;
    letter-spacing: -0.5px;
    color: #07345F;
    margin: 0;
 
    border: 0px;
     }
     label{
       letter-spacing: -0.5px;
     }
   }
}

@media only screen and (max-width: 1024px) {
    
}
