@import "../../assets/sass/colors";
.listing {
   .par-elem{
     transform: translateZ(0px) translateY(13.2432%);
   }
}




@media only screen and (max-width: 1024px) {
    .listing {
    }
}
