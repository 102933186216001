@import "../../assets/sass/colors";


.blog889 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    z-index: 99999;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.pagecontent {
   .mrgtop{
	margin-top:30px
}
}




@media only screen and (max-width: 1024px) {
    .listing {
    }
}
