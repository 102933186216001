@import "../../../assets/sass/colors";
.besoin9 {
  .patk{
       max-width: 100%;
    font-family: Montserrat-Medium;
    margin-top: 10px;
    font-weight: normal !important;

  }
  .bgorange{
    background:#e99f28 !important
  }
}

@media only screen and (max-width: 1024px) {
    
}
