@import "../../assets/sass/colors";
.menudashbord {

    #dash_menu{
     .block_box{
           background:#e5f6f7;
           border:none;
     }
     .user-profile-menu li a{
         font-weight: 500;
         color: #073460;
     }
     .logomenu{
      text-align:center;
      margin-bottom:10px;
      img{
            width: 90px;
            margin: 0 auto;
      }
     }

     ul.no-list-style{
       img{
         margin-right: 20px;
       }
     }
     .user-profile-menu li a.user-profile-act{
      // background:none;
     }

     .user-profile-menu li:first-child, .user-profile-menu li{border:none}
     .logout{
      a{
         font-weight: 500;
    color: #073460;
    position: relative;
    display: block;
    padding: 14px 20px;
    img{
     margin-right: 20px;
    }
      }
     }
  
    }

    .abonnement{
      
           background: #FFF;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    padding: 10px;
    .imgprofil{
     font-size: 11px;
    color: #073460;
    img{
     width: 55px;
    }
    }
    .flx{
      display:flex;
      justify-content: space-between;
      margin-top:6px;
    }
    .cabonne{
      width: 48%;
      padding-left:6px;
      padding-right:6px;
      padding-bottom:8px;
    }
    h5{
     color:#e99f28 !important;
     padding: 0;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    font-size: 12px;
    }
    .cntabn{
    padding-bottom:10px !important;
            p{
                color: #21578b;
    font-size: 10px;
    margin-bottom: 2px;
    text-align: center;
            }
    }
    button{ width: 100%;
    padding: 3px !important;
    border-radius: 19px;font-size: 11px;}

    }
    
}
.profilImg{
  border-radius: 30px !important;
  height: 60px !important;
  width: 60px !important;
}
@media only screen and (max-width: 900px) {
    
}

.menudashbord #dash_menu .block_box {
  background: #dcf2fb !important;
  border: none;
}
.bg_img1{
  background-image:url('../../../public/images/icones/iconpromxim.png');
  background-size: 30px 260px;
  background-repeat: no-repeat;
  padding-left: 45px;
  margin-left: 15px;
  background-position: 0 10px;
}
.bg_img2, .bg_img2:hover{
  background-color: #dcf2fb !important;
  background-image:url('../../../public/images/icones/iconpromxim.png');
  background-repeat: no-repeat;
  padding-left: 45px !important;
  margin-left: 15px;
  background-size: 35px 330px;
  background-position: 0 -30px;
}
.bg_img3, .bg_img3:hover{
  background-color: #dcf2fb !important;
  background-image:url('../../../public/images/icones/iconpromxim.png');
  background-repeat: no-repeat;
  padding-left: 45px !important;
  margin-left: 15px;
  background-size: 35px 330px;
  background-position: 0 -65px;
}
.bg_img4, .bg_img4:hover{
  background-color: #dcf2fb !important;
  background-image:url('../../../public/images/icones/iconpromxim.png');
  background-repeat: no-repeat;
  padding-left: 45px !important;
  margin-left: 15px;
  background-size: 35px 330px;
  background-position: 0 -102px;
}
.bg_img5, .bg_img5:hover{
  background-color: #dcf2fb !important;
  background-image:url('../../../public/images/icones/iconpromxim.png');
  background-repeat: no-repeat;
  padding-left: 45px !important;
  margin-left: 15px;
  background-size: 35px 330px;
  background-position: 0 -142px;
}
.bg_img6, .bg_img6:hover{
  background-color: #dcf2fb !important;
  background-image:url('../../../public/images/icones/iconpromxim.png');
  background-repeat: no-repeat;
  padding-left: 45px !important;
  margin-left: 15px;
  background-size: 35px 330px;
  background-position: 0 -183px;
}
.bg_img7, .bg_img7:hover{
  background-color: #dcf2fb !important;
  background-image:url('../../../public/images/icones/iconpromxim.png');
  background-repeat: no-repeat;
  padding-left: 45px !important;
  margin-left: 15px;
  background-size: 35px 330px;
  background-position: 0 -222px;
}
.bg_img8, .bg_img8:hover{
  background-color: #dcf2fb !important;
  background-image:url('../../../public/images/icones/iconpromxim.png');
  background-repeat: no-repeat;
  padding-left: 45px !important;
  margin-left: 15px;
  background-size: 35px 330px;
  background-position: 0 -258px;
}
.bg_img9, .bg_img9:hover{
  background-color: #dcf2fb !important;
  background-image:url('../../../public/images/icones/iconpromxim.png');
  background-repeat: no-repeat;
  padding-left: 45px !important;
  margin-left: 15px;
  background-size: 35px 330px;
  background-position: 0 -294px;
}

.bg_img10, .bg_img10:hover{
  background-color: #dcf2fb !important;
  background-image:url('../../../public/images/icones/iconeabonner.png');
  background-repeat: no-repeat;
  padding-left: 45px !important;
  margin-left: 15px;
    background-position: 5px 10px;
    background-size: 29px;
}

