@import "../../../assets/sass/colors";
#besoin3 {
.inputFileInvis{
  height: 75px;
  background: transparent;
  opacity: 0;
  z-index: 99999;
  width: 100%;
}
.bsy77{
    padding:0;
    .process-item{
      background:none;
    }
    .process-img {
    min-height: 55px;
   }
}
    .section-title{
      b{
            font-weight: normal;
            font-family: 'Montserrat-Medium'
      }
    }

 
       ul.photocadre{
      margin:0px;
      margin-top:10px;
      padding:0px;
      clear: both;
          overflow: hidden;


  li{
   list-style: none;
    float: left;
    background: #FFF;
    padding: 10px 2px;
    color: #073460;
    width: 170px;
    font-size: 15px;
    text-align: center;
    border-radius: 7px;
    margin-right: 15px;
    cursor: pointer;
    margin-bottom: 10px;
    border: 1px solid #073460;
    min-height: 97px;

    }


    li.imgbg{
      background:url(../../../../public/images/Groupe7130.png);
          background-repeat: no-repeat;
           background-position: 62px 24px;
          background-size: 57px;
    }

       li.videobg{
      background:url(../../../../public/images/Groupe7131.png);
          background-repeat: no-repeat;
           background-position: 62px 24px;
          background-size: 57px;
    }
}
 
}

@media only screen and (max-width: 1024px) {
    #besoin3 {
        
    }
}
