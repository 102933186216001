@import "../../assets/sass/colors";
.messages {
   .notfound{
    text-align: center;
    margin-top: 20%;
}
.scroolmessage{
    height: 500px;
    overflow-y: scroll;
}
 .dashboard-header {
    position: relative;
    z-index: 5;
    padding: 9px 27px;
    border-radius: 10px 10px 0 0;
    text-align: left;
    color: #fff;
    padding-bottom: 2px !important;
    background: #4DB7FE;

    h3{
      font-size:20px
    }

   }
    .chat-box{
	   border-left: 1px solid #eee;
	}
	.chat-wrapper .chat-widget_input{width: 99%;}
	.message-counter{text-align:center}
	.chat-contacts-item .chat-contacts-item-text span {
     
    right: -11px;
    top: 7px;
    
}
}

@media only screen and (max-width: 1024px) {
     
}
