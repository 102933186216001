$primary:#073460;
$primary-transparent:rgba(205, 52, 39, .2);
$dark: #1e1f28;
$text-color: #1e1f28;
$background: #f9fafd;
$grey: rgba(244, 246, 251, .3);
$grey2: #a6a6a6;
$grey3:#a9b6c4;
$grey4:#404148;
$grey5:#d5ddea;
$grey6:#d7d5dd;
$grey7:#989fb0;
$green: #80adb2;
$green2:rgba(226 ,235 ,239 , .5);
$dark-transparent:rgba(30 ,31 ,40 , .3);
$green-transparent:rgba(128, 173, 178,.3);
$secondary:white;



