@import "../../assets/sass/colors";

.modalhomexl800 .modal-content{
  background:#000;
  h2{
  color:#FFF
  }
  .modal-header{
    border:none
  }
  .btn-close{
  background-color:#FFF
  }
  .down-btn{
  box-shadow:none
  }
  .modal-footer{
    border:none
  }
  img{width:100%}
}

.home {
.slick-prev:before{
  background-image:url('../../../public/images/boutonretour.png');
     content: '';
    width: 30px;
    background-size: cover;
    height: 30px;
    display: block;
    background-repeat: no-repeat;
}
.slick-next:before{
    background-image:url('../../../public/images/boutonavance.png');
     content: '';
    width: 30px;
    background-size: cover;
    height: 30px;
    display: block;
    background-repeat: no-repeat;
}


.bg-parallax-wrap{
      background-image: linear-gradient(45deg,#094675 0,#0d6da2 50%,#1291cc 80%);
}
.offresection{
  .process-item{
  box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 10px;
    background: #FFF;
    margin-bottom:20px;
  }
}
.bg-parallax-wrap, .bg-tabs-wrap{
 
}
.mxp99{
      max-width: 60%;
}
   .slick-next {
    right: -7px;
}
.slick-prev{
      left: -18px;
}
.slick-slider {
    margin-top: 28px;
}
.main-search-input{padding-right:0px}
.serachinput{
  border-radius: 30px;
    padding-right: 20px
}
.intro-item h1{text-align:left}

.main-search-button_2 {
    position: absolute;
    right: 12px;
    width: 40px;
    color: #fff;
    height: 40px;
    top: 13px;
    bottom: 10px;
    text-align: center;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background: #14a6e4;
}
.listitem{
        cursor: pointer;
        border-bottom:1px solid #eee;
        background-color:#FFF;
        text-align:left;
        padding:10px;
        z-index:9999;
    }
.tab667{width: 67%;}
.autocomplete-dropdown-container{
      z-index: 99999;
    position: absolute;
    width: 100%;
    top: 40px;
}
.sectionservices{
   .col-md-3{
   margin-bottom:20px
   }
}
.cadre77990{
    background-repeat: no-repeat !important;
    background-position: left center ;
    background-size: 317px !important;
    padding: 26px !important;
    a{
         background: #4ec5ca !important;
    font-size: 17px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    }
}
.f1imo{
       color: #073460;
    font-size: 16px;
    font-weight: normal;
    font-family: 'Montserrat-Medium',sans-serif;
    text-transform: lowercase;
    padding-top: 20px;
    line-height: 23px;
    display: block;
}

.cadre778{
    color: #073460;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0px;
    background:#e8eef7;
    padding:20px;
    text-align: center;
    border-radius:10px;
   h5{
    color:#e99f28
   }
   p{
   color: #073460;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0px;

   }

}
.xx9{
  font-size: 16px;
    color: #e99f28;
}
.xx19{
  font-size: 16px;
    color: #9ddbdb;
}
.xx119{
  font-size: 16px;
    color: #1747ee;
}
.xx1119{
  font-size: 16px;
    color: #14a6e4;
}
.xx11119{
  font-size: 16px;
    color: #65cbcf;
}
.bg0098{
      height: 243px;
    background-repeat: no-repeat;
    background-position: center 1px;
    background-size: 300px;
}
.sectconfi90{
 background: #FFF;
    padding: 60px 0px !important;
    ul{
      overflow: hidden;
      width: 368px;
    margin: 0 auto;
    padding: 0;
    }
    
   li{
      list-style: none;
    float: left;
    margin: 0px 23px;
   }
}
.sectionbesoin{
   img{
      min-height: 172px;
      width: 100%;
      margin-bottom:20px;
   }
   h6{
     color: #073460;
    font-weight: bold;
    font-size: 18px;
    padding-left: 12px;
   }
   p{
    color:#4a6784;
    padding-left: 12px;
   }
}
.cadre888{
    color: #073460;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0px;
    background:#f8f4ee;
    padding:20px;
    text-align: center;
    border-radius:10px;
    padding-bottom: 0px;
   h5{
    color:#e99f28
   }
   p{
   color: #073460;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0px;

   }

}

.sect98{
  line-height: 26px;
    margin-top: 16px;
}
.form-group--nest {
    display: flex;
    flex-flow: row nowrap;
}
.form-group--nest input {
    border-radius: 4px 0 0 4px;
    border-color: #ccc;
    border-right: none;
    font-size: 14px;
   box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
}
.form-group--nest .ps-btn {
    border-radius: 0 4px 4px 0;
    padding-left: 25px;
    padding-right: 25px;
        display: inline-block;
    padding: 15px 45px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #FFF;
    border: none;
    font-weight: 600;
    border-top-right-radius: 4px;
     border-bottom-right-radius: 4px;
    background-color: #073460;
    transition: all .4s ease;
    cursor: pointer;
}
.cadre779{
    color: #073460;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 0px;
    background:#e8f4f4;
    padding:40px;
    text-align: center;
    border-radius:10px;
   h5{
    color:#073460
   }
   p{
   color: #94a9b8;
    font-size: 15px;
    font-weight: normal;
    overflow: hidden;
    margin-bottom: 0px;

   }
   a{
   background: #4ec5ca;
    padding: 20px 50px;
    border-radius: 39px;
    color: #FFF;
    display: block;
    width: max-content;
    margin: 0 auto;
    margin-top: 23px;
    cursor:pointer
   }

}
.sectionvideos{
  
}
.p889{    max-width: 58%;}
.sectOK998{
img{
  float:left;
  width: 37px;
}
  ul{
   margin-top:0px;
   float:left;
    li{
           color: #073460;
    font-size: 14px;
    list-style:none;
    }
  }
}
.cms778{
margin-bottom:10px;
  img{
  width:100%
  }
}
.cardservice{
box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 18px;
    background: #FFF;
    cursor: pointer;
   img{
    width: 100%;
    border-radius: 10px;
    max-height: 142px;
   }
  
   h4{
   color: #073460;
    font-size: 14px;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
   }
}
.radionbtnhome{
   width: 300px;
    margin: 0 auto;
        margin-top: 40px;
    ul{
        margin: 0px;
    padding: 0px;
    background: #073460;
    border-radius: 30px;
    padding: 5px;
        overflow: hidden;
      li{
          list-style: none;
    float: left;
    width: 50%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #FFF;
   
      }
          li.active{
            background: #FFF;
    border-radius: 30px;
    color: #073460;
         
      }

    }
}
.intro-item h3{
 text-align:left;font-weight: 100;
    font-size: 17px;
    font-family: 'Montserrat-Medium';margin-bottom: 40px;
}
.main-search-input:before{display:none}
.homflat{
  min-height:400px;
  .intro-item{
     min-height:400px;    padding-top: 80px;
  }
}
.bgreviews{
  background:#f7f0e4;
  border-radius:10px;
  padding:20px;
  h5{
    line-height: 33px;
    font-size: 25px;
  }
  .notereview{
        color: #4ec5ca;
    font-weight: bold;
    font-size: 30px;
  }
  .listing-rating i {
    font-size: 15px;
  }
  p{
   color:#4a6784
  }
}
.homebg{    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;}
.main-search-input-tabs {
    margin-top: 33px;
}
 padding-top: 60px;
   .li778{
        
    left: 22px;
    color: #FFF;
    font-weight: bold;
    letter-spacing: 1px;
    top: 11px;
   
    font-size: 14px;

   }
.tabs-menu{
  padding-left: 0%;
}
.hero-categories_title{font-size:18px}
.current a{
    border-radius: 21px !important;
    color: #FFF !important;
}
.current a.tain1{
     background: #F75C96;
}

.current a.tain2{
     background: #5ECFB1;
}

.current a.tain3{
     background: #65a4dd;
}

.current a.tain4{
     background: #cba741;
}
.particles-wrapper{padding:30px 0px;}
   .autocomplete-container{
     width: 100%;
     border-right: 0px;
   }
   .pd889{padding-top: 30px;padding-bottom: 30px;}
   .header-sec-link a{text-align:center}
   .hero-categories_title{display:block !important}
   .header-assistant-button {
   text-transform: uppercase;
   background: #069d75 !important;
    position: relative;
    height: 50px;
    line-height: 50px;
    border: none;
    color: #fff;
    border-radius: 30px;
    padding-left:20px;
    padding-right:20px;
    cursor: pointer;
    font-weight: 600;
   margin-bottom:25px
   
   }
   .listing-item{
     width: 100%;
   }
   .grid-item-holder{
     height:auto !important
   }
   h4{    text-transform: uppercase;}
 
}

  .process-wrap-home .process-item{
   border: none;
    background: #FFF;
    padding:0px;
    padding-bottom: 22px;

   }
    .process-wrap-home .process-item img{
      width:100%;
      min-height: 126px;

   }
    .process-wrap-home .process-item img.first{
      min-height: 126px;

   }
   .process-wrap-home .pr-dec{display:none}
   .pad665456{padding-bottom: 0px;}
  .modalhomexl990  .modal-header{border:none}
  .modalhomexl990  .modal-footer{border:none}

  .processhome990{
      width: 322px;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    clear: both;
     a{
         width: 100%;
         background: #384F95 !important;
         margin-top: 0px;
         color:#FFF

     }
  }

.aideDomicile1, .coiffure1, .coursStandard1, .demenagement1, .demenagement11, .gardeanimaux1, .gardeenfants1, .informatique1, .jardinage1{
  margin-top: 50px;
    margin-bottom: 50px;
}

.aideDomicile2 .col-md-8, .coiffure2 .col-md-8, .coursStandard2 .col-md-8, .demenagement2 .col-md-8, .demenagement22 .col-md-8, .gardeanimaux2 .col-md-8, .gardeenfants2 .col-md-8, .informatique2 .col-md-8, .jardinage2 .col-md-8{
 margin-bottom: 30px;
}
.aideDomicile3 .col-md-8, .coiffure3 .col-md-8, .coursStandard3 .col-md-8, .demenagement3 .col-md-8, .demenagement33 .col-md-8, .gardeanimaux3 .col-md-8, .gardeenfants3 .col-md-8, .informatique3 .col-md-8, .jardinage3 .col-md-8{
 margin-bottom: 30px;
}
.aideDomicile4 .col-md-8, .coiffure4 .col-md-8, .coursStandard4 .col-md-8, .demenagement4 .col-md-8, .demenagement44 .col-md-8, .gardeanimaux4 .col-md-8, .gardeenfants4 .col-md-8, .informatique4 .col-md-8, .jardinage4 .col-md-8{
 margin-bottom: 30px;
}


.aideDomicile1 .btnform, .coiffure1 .btnform, .coursStandard1 .btnform, .demenagement1 .btnform, .demenagement11 .btnform, .gardeanimaux1 .btnform, .gardeenfants1 .btnform, .informatique1 .btnform, .jardinage1 .btnform{
     background: #e99f28 !important;
    padding: 6px !important;
    margin-top: 30px;
}

.aideDomicile2 .btnform, .coiffure2 .btnform, .coursStandard2 .btnform, .demenagement2 .btnform, .demenagement22 .btnform, .gardeanimaux2 .btnform, .gardeenfants2 .btnform, .informatique2 .btnform, .jardinage2 .btnform{
     background: #e99f28 !important;
    padding: 6px !important;
    margin-top: 30px;
}

.aideDomicile3 .btnform, .coiffure3 .btnform, .coursStandard3 .btnform, .demenagement3 .btnform, .demenagement33 .btnform, .gardeanimaux3 .btnform, .gardeenfants3 .btnform, .informatique3 .btnform, .jardinage3 .btnform{
     background: #e99f28 !important;
    padding: 6px !important;
    margin-top: 30px;
}
.aideDomicile4 .btnform, .coiffure4 .btnform, .coursStandard4 .btnform, .demenagement4 .btnform, .demenagement44 .btnform, .gardeanimaux4 .btnform, .gardeenfants4 .btnform, .informatique4 .btnform, .jardinage4 .btnform{
     background: #e99f28 !important;
    padding: 6px !important;
    margin-top: 30px;
}

.blocfrequence {
    cursor: pointer;
    border-radius: 3px !important;
    margin-bottom: 16px;
 
}
.blocfrequence .p1 {
    font-size: 17px !important;
    margin-bottom: 0;
    padding: 5px;
   
}
.coiffurebox img{
      width: 104px;
    height: 104px;
}

.coiffure3 .coiffurebox p{
   font-family: 'Montserrat-Bold';
    font-size: 18px;
}
.coiffure3 .listeservice{
      margin-top: 35px;
      width: 95%;
}
.divservice {
  display: flex;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
    .col-md-2{
    padding-right:0px
    }
}

.divservice .col-md-8 {
  margin-bottom: 0px !important;
}
.divservice .col-md-8 span{
   font-family: 'Montserrat-Bold';
    padding-left: 10px;
}

.coiffure4 .react-datepicker, .demenagement3 .react-datepicker, .demenagement13 .react-datepicker,  .demenagement13 .react-datepicker, .informatique3 .react-datepicker  {
  width:70%;
  display: flex;
  background:#FFF;
}

.demenagement3 .react-datepicker__month-container, .demenagement3 .react-datepicker__header,.demenagement13 .react-datepicker__month-container,  .demenagement13 .react-datepicker__header , .informatique3 .react-datepicker__header , .informatique3 .react-datepicker__month-container {
  background:#FFF;
}

.demenagement12 {
  .span_cercle, .s_qte{
   margin-right:20px;
  }
}
@media only screen and (max-width: 1024px) {
    .home {


    }
}

 