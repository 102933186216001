@import "../../assets/sass/colors";
.commentcafonctionne {
    .video {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    position:relative
     .img{
       width: 100%;
     }
}
.landing-img{
	    position: absolute;
    top: 97px;
    width: 100%;
    z-index: 999;

}
.landing-video {
    z-index: 1;
    width: 750px;
    height: 315px;
    margin: 22px 0 0 95px;
    overflow: hidden;
    position: absolute;
    top: 24px;
    margin: 0 auto;
     img{
           width: 547px;
     }
}
.section-title{
	 padding-bottom: 15px;
}
.process-item{
	 background: #f9f9f9;
    border: 0;
    text-align: center;
    padding-bottom: 1px;
}
.process-wrap{
	 margin-top:50px
}
.process-wrap li{
    
}
.offreaction{
    color: #073460;
    display: block;
    text-align: center;
    max-width: 160px;
    margin: 0 auto;
    border: 1px solid;
    border-radius: 15px;
    font-weight: bold;
    padding: 5px;
    margin-bottom: 20px;
    margin-top: 15px;
    cursor:pointer
}
.process-img{    margin-bottom: 30px;}
.process-count {
    position: relative;
    z-index: 10;
    font-size: 17px;
    font-weight: bold;
    left: unset;
    margin-left: 0px;
    padding: 16px 21px;
        margin: 0 auto;
    line-height: 19px;
}
.priceoffre{color: #4ec5ca;
    
    font-size: 20px;
    span{
      font-weight: bold;
    }}
.process-end{
	 display:none
}
.pr-dec{display:none}
.time-line-icon{
    margin-top: 68px;
}
}




@media only screen and (max-width: 1024px) {
    .listing {
    }
}
