@import "../../../assets/sass/colors";

 .pprmmp0{
      padding: 5px !important;
    border-radius: 4px !important;
 }
 .abonnement{
  .cabonne{
    box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
   -webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
   -moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 10px;
    background: #FFF;
        overflow: hidden;
    .title{

    text-align: center;
    padding: 10px;
   
    color: #FFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    }
    .cr_classic{ background: #073460;}
    .cr_basique{ background: #e99f28 !important;}
    .cr_premuim{ background: #4ec5ca !important;}
    .cntabn{
         padding-bottom: 22px;
    padding-top: 13px;
      h5{
             color: #646464;
            font-size: 15px;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 10px;
            sup{
                 font-weight: normal;
    font-family: 'Montserrat-Medium';
            }
      }
      ul{
      min-height: 70px;
            margin: 0px;
           padding: 0px;
           padding-left: 8px;
            overflow: hidden;

            margin-bottom:15px;
       li{
       list-style:none;
        span{
              font-size: 11px;
             letter-spacing: -1px;
             padding-left: 5px;
             color:#646464;
        }
        img{
              width: 14px;
        }
       }
      }
      button{
        border-radius: 20px;
    float: none !important;
    margin: 0 auto;
    width: 124px;
      }
    }
  }
  .inlineblk{
       width: 137px;
    display: inline-block;
  }
  .cstm09{
    a{
      border: 1px solid #07345F;
    padding: 8px;
    border-radius: 6px;
    color: #07345F;
    }
  }
  .imgprofil{
    text-align:center
  }
  .flx889bg{
   background: #d5ecee;
    padding: 3px 3px;
    border-radius: 4px;
    margin-bottom:8px;
  }
  .flx889{
     display: flex;
     align-items: center;
     justify-content: space-between;
     .item1{

     }
    .item2{
      .it1{
            background: #FFF !important;
            border-radius: 3px !important;
            color: #4ec5ca !important;
             padding: 2px 8px;
            font-size: 12px;
            cursor:pointer;
      }
      .it2{
            color: #646464 !important;
                font-size: 12px;
                 padding: 2px 8px;
                 cursor:pointer;
      }
     }
  }
  .clrbl9{
        color: #073460;
         font-size: 11px;
        letter-spacing: -0.5px;
  }

  .MuiSlider-root {
    color: #4ec5ca;
    padding-bottom: 2px;
    }
    .flx881{
     margin-bottom:10px;
     overflow: hidden;
     label{
       margin-bottom:2px
     }
     input{ padding-left: 7px !important;}
     button{
      padding: 5px !important;
    border-radius: 4px;
    margin-top: 0px;
     }
    }
 }
 .profilImg{
  border-radius: 30px !important;
  height: 60px !important;
  width: 60px !important;
}
@media only screen and (max-width: 1024px) {
    
}
