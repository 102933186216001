@import "../../assets/sass/colors";
.avis-item {
  position:relative;
   strong{
    color:#4ec5ca;
    display:block;
   }
   .ddavis{    font-weight: normal;
    font-size: 11px;
    color: #73777b;
    position: absolute;
    width: max-content;
    right: 25px;}
   .listing-rating{
   margin-bottom:5px
   }
   .listing-rating span{
     font-weight:bold;
     color:#073460;
     padding-left:10px

   }

   .testimonilas-text {
    padding: 20px;
 
    background: #FFF;
    }
    .testimonilas-text:before, .testimonilas-text:after{
    display:none
    }
    .testimonilas-avatar h3 {
    font-weight: normal;
    color: #73777b;
    font-family: 'Montserrat-Medium';
    font-size: 13px;
}
 
    .testi-avatar {
    
    left: 46%;
    }
}

@media only screen and (max-width: 1024px) {
    .restau-item {
    }
}
