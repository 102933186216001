@import "../../../assets/sass/colors";
#besoin4 {
  
  .loading{
   text-align:center;
    img{
      width: 128px;
    }
  }
 
}

@media only screen and (max-width: 1024px) {
    #besoin4 {
        
    }
}


.modal-header-container {
  display: flex;
  align-items: center;
}

.circular-image {
  // border: 1px solid rgb(102, 100, 100);
  border-radius: 50%;
  // overflow: hidden;
  // margin-left: 40px; /* Adjust this margin as needed */
  // margin-top:10px
  
}

.modal-title {
  flex: 1;
  font-size: 20px; /* Adjust the font size as needed */
  color: rgb(17, 17, 240);
  margin-left: 20px;
}